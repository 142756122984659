import { Button, Col, Modal, Row, Slider, message } from "antd";
import React, { useEffect, useState } from "react";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { InfoCircleOutlined, LeftOutlined } from "@ant-design/icons";
import Text from "../components/Text";
import icon1 from "../assets/Svg/usdcCoin.svg";
import icon2 from "../assets/Svg/Diamond.svg";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import upcirle from "../assets/Svg/FiArrowUpCircle.svg";
import Web3 from "web3";
import { erc20ABI, useAccount, useChainId } from "wagmi";
import {
  getPoolAddress,
  getPoolData,
  getPrice,
  removeLiquidity,
} from "../utils/poolFunctions";
import UniswapV3Pool from "@uniswap/v3-core/artifacts/contracts/UniswapV3Pool.sol/UniswapV3Pool.json";
import {
  computePoolAddress,
  Pool,
  Position,
  NonfungiblePositionManager,
} from "@uniswap/v3-sdk";
import { Token, Percent, CurrencyAmount } from "@shidoglobal/sdk-core";
import { FACTORY_ADDRESS, POSITION_MANAGER_ADDRESS } from "../constants";

import { fromReadableAmount } from "../utils/tradeUtils";
import FiCheck from "../assets/FiCheckCircle.svg";
import { formatNumberUniversal } from "../utils";
import { useError } from "../contexts/ErrorContext";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";

interface RemoveLiquidityModalProps {
  open: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  activePosition: any;
  positionIds: any;
  loading: boolean;
  setLoading: any;
  setLiquidityModel: React.Dispatch<React.SetStateAction<boolean>>;
  refreshPosition: any;
}

const RemoveLiquidityModal = (props: RemoveLiquidityModalProps) => {
  const {
    open,
    setState,
    positionIds,
    activePosition,
    setLiquidityModel,
    refreshPosition,
  } = props;
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const [percentage, setParcentage] = useState<number>(25);
  const [approveOpen, setApproveOpen] = useState<boolean>(false);
  const { connector, address } = useAccount();
  const [tokenPrices, setTokenPrices] = useState({ token0: 0, token1: 0 });
  const [success, setSuccess] = useState<boolean>(false);
  const { setError } = useError();

  const handleOk = () => {
    setSuccess(false);
    setState(false);
  };
  const handleCancel = () => {
    setSuccess(false);
    setState(false);
  };

  const hangleSlider = (value: number) => {
    setParcentage(value);
  };

  const handleRemoveLiquidity = async () => {
    props.setLoading(true);
    try {
      const web3 = new Web3(await connector?.getProvider());

      const chain_id = Number(await web3.eth.getChainId());

      const token0 = new Token(
        Number(chain_id),
        props.activePosition.token0,
        Number(props.activePosition.token1Decimals),
        props.activePosition.token0Symbol,
        props.activePosition.token0Symbol
      );

      const token1 = new Token(
        Number(chain_id),
        props.activePosition.token1,
        Number(props.activePosition.token1Decimals),
        props.activePosition.token1Symbol,
        props.activePosition.token1Symbol
      );

      const poolAddress = computePoolAddress({
        factoryAddress: FACTORY_ADDRESS,
        tokenA: token0,
        tokenB: token1,
        fee: props.activePosition.fee,
      });

      const poolContract = new web3.eth.Contract(
        UniswapV3Pool.abi,
        poolAddress
      );

      const poolData = await getPoolData(poolContract);

      const pool = new Pool(
        token0,
        token1,
        Number(poolData.fee),
        poolData.sqrtPriceX96,
        poolData.liquidity,
        Number(poolData.tick)
      );

      const positionSDK = new Position({
        pool,
        liquidity: Number(activePosition.liquidity),
        tickLower: Number(activePosition.tickLower),
        tickUpper: Number(activePosition.tickUpper),
      });

      const liquidityPercentage = new Percent(percentage, 100);

      const positionId = positionIds[props.activePosition.positionIdIndex];

      const collectOptions: Omit<any, "tokenId"> = {
        expectedCurrencyOwed0: CurrencyAmount.fromRawAmount(
          token0,
          fromReadableAmount(
            BigNumber(activePosition.tokensOwed0),
            activePosition.token0Decimals
          )
        ),
        expectedCurrencyOwed1: CurrencyAmount.fromRawAmount(
          token1,
          fromReadableAmount(
            BigNumber(activePosition.tokensOwed1),
            activePosition.token1Decimals
          )
        ),
        recipient: address,
      };

      const { calldata, value } =
        NonfungiblePositionManager.removeCallParameters(positionSDK, {
          tokenId: positionId.toString(),
          liquidityPercentage,
          slippageTolerance: new Percent(50, 10_000),
          deadline: Math.floor(Date.now() / 1000) + 60 * 20,
          collectOptions,
        });

      const transaction: any = {
        data: calldata,
        to: POSITION_MANAGER_ADDRESS,
        value: value,
        from: address,
      };

      const estimateGas = await web3.eth.estimateGas(transaction);

      transaction["gas"] = estimateGas.toString();

      const tx = await web3.eth.sendTransaction(transaction);

      return tx.transactionHash;
    } catch (e: any) {
      setError({
        message: "Something went wrong while removing liquidity",
        description: e.message,
        type: "error",
      });
    } finally {
      props.setLoading(false);
    }
    return null;
  };

  useEffect(() => {
    const getData = async () => {
      if (activePosition && activePosition.poolSqrtPrice) {
        const prices = await getPrice({
          sqrtPriceX96: Number(activePosition.poolSqrtPrice),
          Decimal0: Number(activePosition.token1Decimals),
          Decimal1: Number(activePosition.token0Decimals),
        });

        setTokenPrices(prices);
      }
    };

    getData();
  }, [activePosition]);

  return (
    <>
      <Modal
        footer={false}
        closable={false}
        centered
        open={open}
        onCancel={handleCancel}
        onOk={handleOk}
        width={
          success ? (xs ? 300 : 550) : lg ? 860 : md ? 780 : xs ? 367 : 560
        }
        styles={{ content: { padding: lg ? "24px" : xs ? "12px" : "16px" } }}
      >
        {!success && (
          <Row style={{ gap: "16px", flexDirection: md ? "row" : "column" }}>
            <Col
              style={{
                width: lg ? "458px" : md ? "394px" : "100%",
                padding: "16px",
                borderRadius: "12px",
                background: "#173155",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Row
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <LeftOutlined
                  style={{ color: "#ffffff63", cursor: "pointer" }}
                  onClick={() => {
                    setState(false);
                    setLiquidityModel(true);
                  }}
                />
                <Text size="lg">Remove Liquidity</Text>
                <InfoCircleOutlined style={{ color: "#ffffff63" }} />
              </Row>
              <Row
                style={{
                  padding: "16px 0px",
                  borderTop: "1px solid rgba(255, 255, 255, 0.10)",
                  borderBottom: "1px solid rgba(255, 255, 255, 0.10)",
                }}
              >
                <h1 className="remove-liquidity-heading-percent">
                  {percentage}%
                </h1>
                <Row
                  style={{ width: "100%", gap: "10px", marginBottom: "12px" }}
                >
                  <Col
                    className={`remove-liquidity-percent-select ${
                      percentage === 25
                        ? "remove-liquidity-percent-select-active"
                        : ""
                    }`}
                    onClick={() => {
                      setParcentage(25);
                    }}
                  >
                    25%
                  </Col>
                  <Col
                    className={`remove-liquidity-percent-select ${
                      percentage === 50
                        ? "remove-liquidity-percent-select-active"
                        : ""
                    }`}
                    onClick={() => {
                      setParcentage(50);
                    }}
                  >
                    50%
                  </Col>{" "}
                  <Col
                    className={`remove-liquidity-percent-select ${
                      percentage === 75
                        ? "remove-liquidity-percent-select-active"
                        : ""
                    }`}
                    onClick={() => {
                      setParcentage(75);
                    }}
                  >
                    75%
                  </Col>{" "}
                  <Col
                    className={`remove-liquidity-percent-select ${
                      percentage === 100
                        ? "remove-liquidity-percent-select-active"
                        : ""
                    }`}
                    onClick={() => {
                      setParcentage(100);
                    }}
                  >
                    100%
                  </Col>
                </Row>
                <Slider
                  value={percentage}
                  max={100}
                  min={1}
                  onChange={hangleSlider}
                  style={{ width: "100%" }}
                />
                <Text size="xs" style={{ color: "#ffffff63" }}>
                  Manually adjust the amount with the slider*
                </Text>
              </Row>
              <Row
                style={{
                  paddingBottom: "16px",
                  borderBottom: "1px solid rgba(255, 255, 255, 0.10)",
                  gap: "8px",
                }}
              >
                <Row
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <img
                      src={activePosition.token0image}
                      style={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "50%",
                        objectFit: "contain",
                      }}
                    />
                    <Text size="sm">{activePosition.token0Symbol}</Text>
                  </Col>
                  <Text size="sm">
                    {formatNumberUniversal(activePosition.pooledToken0 || "0")}
                  </Text>
                </Row>
                <Row
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <img
                      src={activePosition.token1image}
                      style={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "50%",
                        objectFit: "contain",
                      }}
                    />
                    <Text size="sm">{activePosition.token1Symbol}</Text>
                  </Col>
                  <Text size="sm">
                    {formatNumberUniversal(activePosition.pooledToken1 || "0")}
                  </Text>
                </Row>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={() => setApproveOpen(true)}
                    loading={props.loading}
                  >
                    Remove
                  </Button>
                </Col>
                {props.loading && (
                  <Text
                    size="xs"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "12px",
                    }}
                  >
                    Processing, this may take some time...
                  </Text>
                )}
              </Row>
            </Col>
            <Col style={{ flex: "1", display: "flex" }}>
              <Row
                style={{ flexDirection: "column", gap: "16px", width: "100%" }}
              >
                <Row className="your-position-container">
                  <Text size="sm" style={{ fontWeight: "500" }}>
                    Your position
                  </Text>
                  <Row
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: "8px",
                      backgroundColor: "#45454A",
                      padding: "5px 12px 5px 5px",
                    }}
                  >
                    <Col style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={activePosition.token0image}
                        style={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "50%",
                          objectFit: "contain",
                        }}
                      />
                      <img
                        src={activePosition.token1image}
                        style={{
                          width: "24px",
                          height: "24px",
                          position: "relative",
                          left: "-6px",
                          borderRadius: "50%",
                          objectFit: "contain",
                        }}
                      />
                      <Text size="md">
                        {activePosition.token0Symbol}/
                        {activePosition.token1Symbol}
                      </Text>
                    </Col>
                    <Text size="sm">{percentage}%</Text>
                  </Row>
                  <Row style={{ gap: "8px" }}>
                    <DataRow
                      name="Your Pool Share"
                      value={
                        String(
                          Number(
                            activePosition?.liquidityPercentage?.toString()
                          ).toFixed(2)
                        ) + "%"
                      }
                    />
                    <DataRow
                      name={activePosition.token0Symbol}
                      value={formatNumberUniversal(
                        activePosition.pooledToken0 || "0"
                      )}
                      icon={activePosition.token0image}
                    />
                    <DataRow
                      name={activePosition.token1Symbol}
                      value={formatNumberUniversal(
                        activePosition.pooledToken1 || "0"
                      )}
                      icon={activePosition.token1image}
                    />
                  </Row>
                </Row>
                <Row className="your-position-container">
                  <Text
                    size="sm"
                    style={{ fontWeight: "500", marginBottom: "16px" }}
                  >
                    Price
                  </Text>
                  <Text
                    size="sm"
                    style={{
                      fontWeight: "400",
                      color: "#ffffff63",
                      marginBottom: "8px",
                    }}
                  >
                    1 {activePosition.token0Symbol} ={" "}
                    {formatNumberUniversal(String(tokenPrices.token0) || "0")}{" "}
                    {activePosition.token1Symbol}
                  </Text>
                  <Text
                    size="sm"
                    style={{ fontWeight: "400", color: "#ffffff63" }}
                  >
                    1 {activePosition.token1Symbol} ={" "}
                    {formatNumberUniversal(String(tokenPrices.token1) || "0")}{" "}
                    {activePosition.token0Symbol}
                  </Text>
                </Row>
              </Row>
            </Col>
          </Row>
        )}
        {success && (
          <Row style={{ width: "100%" }}>
            <Row style={{ justifyContent: "space-between", width: "100%" }}>
              <Text size="md" style={{ fontSize: 20 }}>
                Remove Liquidity
              </Text>
              <CloseOutlined
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  setSuccess(false);
                  handleCancel();
                }}
              />
            </Row>
            <Row
              style={{
                width: "100%",
                marginTop: "16px",
                backgroundColor: "#173155",
                borderRadius: "12px",
                padding: "30px 0px",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <img src={FiCheck} />
              <Text size="lg" style={{ textAlign: "center" }}>
                Liquidity removed succesfully!
              </Text>
            </Row>
          </Row>
        )}
      </Modal>

      <RemoveLiquidityApproveModal
        setStateApprove={setApproveOpen}
        openApprove={approveOpen}
        percentage={percentage}
        activePosition={activePosition}
        tokenPrices={tokenPrices}
        handleRemoveLiquidity={handleRemoveLiquidity}
        refreshPosition={refreshPosition}
      />
    </>
  );
};

export default RemoveLiquidityModal;

interface DataRowProps {
  name: string;
  value: string;
  icon?: string;
}
const DataRow = (props: DataRowProps) => {
  const { name, value, icon } = props;
  return (
    <Row
      style={{
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Text size="sm" style={{ color: "#ffffff63" }}>
        {name}
      </Text>
      <Col style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Text size="sm">{value}</Text>
        {icon && (
          <img
            src={icon}
            style={{
              height: "18px",
              width: "18px",
              borderRadius: "50%",
              objectFit: "contain",
            }}
          />
        )}
      </Col>
    </Row>
  );
};

interface RemoveLiquidityApproveModalProps {
  openApprove: boolean;
  setStateApprove: React.Dispatch<React.SetStateAction<boolean>>;
  percentage: number;
  activePosition: any;
  tokenPrices: any;
  handleRemoveLiquidity: any;
  refreshPosition: any;
}

const RemoveLiquidityApproveModal = (
  props: RemoveLiquidityApproveModalProps
) => {
  const {
    openApprove,
    setStateApprove,
    activePosition,
    percentage,
    tokenPrices,
    handleRemoveLiquidity,
    refreshPosition,
  } = props;
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const [step, setStep] = useState<number>(1);
  const [txHash, setTxHash] = useState<any>(null);
  const [transactionSuccessful, setTransactionSuccessful] =
    useState<boolean>(false);

  const handleApproveOk = () => {
    setStateApprove(false);
  };

  const [loading, setLoading] = useState<boolean>(false);

  const handleApproveCancel = () => {
    setStateApprove(false);
  };

  const handleRemove = async () => {
    try {
      setLoading(true);
      const transactionHash = await handleRemoveLiquidity();
      if (transactionHash) {
        setTransactionSuccessful(true);
        setTxHash(transactionHash);
        await refreshPosition(activePosition.positionId);
        setStateApprove(false);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (txHash && transactionSuccessful) {
      setStep(2);
    }
  }, [txHash, transactionSuccessful]);

  return (
    <Modal
      footer={false}
      closable={false}
      centered
      open={openApprove}
      onCancel={handleApproveCancel}
      onOk={handleApproveOk}
      width={xxl ? 600 : md ? 550 : xs ? 351 : 560}
      styles={{ content: { padding: md ? "24px" : xs ? "8px" : "16px" } }}
    >
      {step === 1 && (
        <Row
          style={{
            padding: "16px",
            borderRadius: "12px",
            backgroundColor: "#173155",
            gap: "16px",
          }}
        >
          <Row
            style={{
              justifyContent: "center",
              width: "100%",
              position: "relative",
            }}
          >
            <Text size="lg" style={{ fontSize: "600" }}>
              You Will Receive
            </Text>
            <CloseOutlined
              style={{ color: "#ffffff63", position: "absolute", right: "0px" }}
              onClick={handleApproveCancel}
            />
          </Row>
          <Row
            style={{
              padding: "12px",
              backgroundColor: "#062148",
              borderRadius: "12px",
              gap: "16px",
              width: "100%",
            }}
          >
            <CoinDataRow
              name={activePosition.token0Symbol}
              value={formatNumberUniversal(
                String(
                  Number(activePosition.pooledToken0) *
                    (Number(percentage) / 100)
                )
              )}
              icon={activePosition.token0image}
            />
            <Row style={{ justifyContent: "center", width: "100%" }}>
              <Col
                style={{
                  padding: "5px",
                  borderRadius: "8px",
                  backgroundColor: "rgba(255, 255, 255, 0.07)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PlusOutlined />
              </Col>
            </Row>
            <CoinDataRow
              name={activePosition.token1Symbol}
              value={formatNumberUniversal(
                String(
                  Number(activePosition.pooledToken1) *
                    (Number(percentage) / 100)
                )
              )}
              icon={activePosition.token1image}
            />
          </Row>
          <Row
            style={{
              paddingBottom: "16px",
              borderBottom: "1px solid rgba(255, 255, 255, 0.10)",
            }}
          >
            <Text size="xs" style={{ color: "#ffffff63", lineHeight: "20px" }}>
              Please note that the output provided is an estimate. In the event
              of a price fluctuation exceeding 25%, your transaction will be
              automatically reversed.
            </Text>
          </Row>
          <Text size="sm" style={{ fontWeight: "500" }}>
            Your position
          </Text>
          <Row
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "8px",
              backgroundColor: "#45454A",
              padding: "5px 12px 5px 5px",
              width: "100%",
            }}
          >
            <Col style={{ display: "flex", alignItems: "center" }}>
              <img
                src={activePosition.token0image}
                style={{
                  width: "24px",
                  height: "24px",
                  borderRadius: "50%",
                  objectFit: "contain",
                }}
              />
              <img
                src={activePosition.token1image}
                style={{
                  width: "24px",
                  height: "24px",
                  position: "relative",
                  left: "-6px",
                  borderRadius: "50%",
                  objectFit: "contain",
                }}
              />
              <Text size="md">
                {activePosition.token0Symbol}/{activePosition.token1Symbol}{" "}
                Burned
              </Text>
            </Col>
            <Text size="sm">{percentage}%</Text>
          </Row>

          <Row className="your-position-container">
            <Text size="sm" style={{ fontWeight: "500", marginBottom: "5px" }}>
              Price
            </Text>
            <Text
              size="sm"
              style={{
                fontWeight: "400",
                color: "#ffffff63",
              }}
            >
              1 {activePosition.token0Symbol} ={" "}
              {formatNumberUniversal(String(tokenPrices.token0) || "0")}{" "}
              {activePosition.token1Symbol}
            </Text>
            <Text size="sm" style={{ fontWeight: "400", color: "#ffffff63" }}>
              1 {activePosition.token1Symbol} ={" "}
              {formatNumberUniversal(String(tokenPrices.token1) || "0")}{" "}
              {activePosition.token0Symbol}
            </Text>
          </Row>
          <Button
            style={{ width: "100%" }}
            type="primary"
            onClick={() => {
              handleRemove();
            }}
            disabled={loading}
            loading={loading}
          >
            Approve
          </Button>
        </Row>
      )}
      {step == 2 && (
        <Row>
          <Row style={{ flexDirection: "column", gap: "36px", width: "100%" }}>
            <Row
              style={{
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Text
                size={xs ? "md" : "lg"}
                style={{
                  fontWeight: 600,
                  textAlign: "center",
                  fontFamily: "Inter",
                }}
              >
                Transaction Complete
              </Text>
            </Row>
            <Row style={{ justifyContent: "center" }}>
              <img src={upcirle} />
            </Row>
            <Button
              type="primary"
              onClick={() => {
                handleApproveOk();
                setTxHash("");
                setTransactionSuccessful(false);
                setStep(1);
              }}
            >
              Close
            </Button>
            <Text
              size="sm"
              style={{
                color: "#3B87F7",
                fontWeight: "400",
                width: "100%",
                textAlign: "center",
                marginTop: "-20px",
              }}
            >
              <Link to={`https://shidoscan.com/tx/${txHash}`} target="_blank">
                View on BlockChain
              </Link>
            </Text>
          </Row>
        </Row>
      )}
    </Modal>
  );
};

interface CoinDataRowProps {
  icon: string;
  name: string;
  value: string;
}

const CoinDataRow = (props: CoinDataRowProps) => {
  const { icon, name, value } = props;
  return (
    <Row
      style={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Col style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <img
          src={icon}
          style={{
            height: "24px",
            width: "24px",
            borderRadius: "50%",
            objectFit: "contain",
          }}
        />
        <Text size="sm" style={{ fontWeight: "500" }}>
          {name}
        </Text>
      </Col>
      <Text size="sm" style={{ fontWeight: "500" }}>
        {value}
      </Text>
    </Row>
  );
};
