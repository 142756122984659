import React, { useState, useEffect, useRef } from "react";
import {
  Select,
  Row,
  Col,
  Button,
  message,
  Layout,
  InputNumber,
  ConfigProvider,
  Skeleton,
} from "antd";
import { fetchTokenImages } from "../api/ApiCalls";
import { erc20ABI, useAccount } from "wagmi";
import Web3 from "web3";
import {
  getTokenBalance,
  unwrapWShido,
  wrapWShido,
} from "../utils/poolFunctions";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import Text from "../components/Text";
import ArrowDown from "../assets/Svg/FiArrowDown.svg";
import {
  GAS_PRICE,
  OsmosisChainInfo,
  SHIDO_RPC_URL,
  USDC_DENOM,
  USDC_TOKEN_ADDRESS,
  WSHIDO_ADDRESS,
} from "../constants";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useError } from "../contexts/ErrorContext";
import defaultSVG from "../assets/DefaultSVG.svg";
import usdcIcon from "../assets/usdcIcon.png";
import { DownOutlined } from "@ant-design/icons";
import SelectTokenModal from "../components/SelectTokenWrapModal";
import { simulateMsgs } from "../utils/keplrUtils/simulateMsgs";
import { sendMsgs } from "../utils/keplrUtils/sendMsgs";
import { MsgConvertERC20, MsgConvertCoin } from "../codec/shido/erc20/v1/tx";
import { formatNumberUniversal } from "../utils";
import { Coin } from "../codec/cosmos/base/v1beta1/coin";
import { SigningStargateClient, GasPrice } from "@cosmjss/stargate";
import { Registry } from "@cosmjss/proto-signing";
import { SwapToken } from "../types";
import emergency_home from "../assets/Svg/emergency_home.svg";
import check_circle from "../assets/Svg/check_circle.svg";
import WarningModal from "../components/WarningModal";

const Wrap = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const [fromCurrency, setFromCurrency] = useState<any>(null);
  const [toCurrency, setToCurrency] = useState<any>(null);
  const [fromAmount, setFromAmount] = useState(1);
  const [toAmount, setToAmount] = useState(1);
  const [swapTokens, setSwapTokens] = useState<SwapToken[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [tokenBalanceLoading, seTokenBalanceLoading] = useState<boolean>(false);
  const [tokenInBalance, setTokenInBalance] = useState<any>();
  const [tokenOutBalance, setTokenOutBalance] = useState<any>();
  const [cachedTokenImages, setCachedTokenImages] = useState<{
    [key: string]: string;
  }>({});
  const [walletAddress, setWalletAddress] = useState<string | null>(null);
  const [client, setClient] = useState<any>(null);
  const { open } = useWeb3Modal();
  const [tokenImage, setTokenImage] = useState<string>(defaultSVG);
  const { connector, address } = useAccount();
  const [warningModel, setWarningModel] = useState(false);

  const { setError } = useError();
  const lastKnownSequenceRef = useRef<number | null>(null);

  const extractSequenceFromError = (errorMessage: string): number | null => {
    const matches = errorMessage.match(/expected (\d+), got \d+/);
    if (matches && matches[1]) {
      return parseInt(matches[1]);
    }
    return null;
  };

  const getAndUpdateSequence = async (address: string) => {
    try {
      const account = await client.getAccount(address);
      if (!account) {
        throw new Error("Failed to get account info");
      }

      const currentSequence =
        lastKnownSequenceRef.current ?? parseInt(account.sequence);
      return currentSequence;
    } catch (error) {
      console.error("Error getting sequence:", error);
      throw error;
    }
  };

  useEffect(() => {
    setWarningModel(true);
  }, []);

  useEffect(() => {
    const loadData = async () => {
      const tokens: SwapToken[] = [
        {
          id: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
          symbol: "SHIDO",
          name: "SHIDO",
          decimals: 18,
        },
        {
          id: WSHIDO_ADDRESS,
          symbol: "WSHIDO",
          name: "Wrapped SHID",
          decimals: 18,
        },
      ];

      setSwapTokens(tokens);

      const options: any = tokens.map((item) => ({
        value: item.symbol,
        label: item.symbol,
      }));

      if (options.length >= 2) {
        setFromCurrency(options[0].value);
        setToCurrency(options[1].value);
      }
    };

    loadData();
  }, []);

  const loadTokenImages = async () => {
    if (["WSHIDO", "SHIDO"].includes(fromCurrency)) {
      if (cachedTokenImages[WSHIDO_ADDRESS]) {
        setTokenImage(cachedTokenImages[WSHIDO_ADDRESS]);
      } else {
        try {
          const tokenImages = await fetchTokenImages([WSHIDO_ADDRESS]);
          const image =
            tokenImages[Web3.utils.toChecksumAddress(WSHIDO_ADDRESS)];
          setTokenImage(image);
          setCachedTokenImages((prev) => ({
            ...prev,
            [WSHIDO_ADDRESS]: image,
          }));
        } catch (error) {
          console.error("Error fetching token images", error);
        }
      }
    } else {
      setTokenImage(usdcIcon);
    }
  };

  useEffect(() => {
    if (["WSHIDO", "SHIDO"].includes(fromCurrency)) {
      if (!connector) {
        setTokenInBalance(0);
        setTokenOutBalance(0);
        return;
      }
      Promise.all([refreshBalances(), loadTokenImages()]);
    } else if (["USDC (ERC-20)", "USDC (Native)"].includes(fromCurrency)) {
      Promise.all([refreshUSDCBalances(), loadTokenImages()]);
    } else {
      setTokenInBalance(0);
      setTokenOutBalance(0);
    }
  }, [fromCurrency, toCurrency, client, connector]);

  const refreshBalances = async () => {
    seTokenBalanceLoading(true);

    if (["USDC (ERC-20)", "USDC (Native)"].includes(fromCurrency)) {
      seTokenBalanceLoading(false);
      return;
    }

    try {
      const provider = new Web3(await connector?.getProvider());

      if (!address || !provider) {
        return;
      }

      if (fromCurrency) {
        const fromToken = swapTokens.find(
          (item: any) => item.symbol === fromCurrency
        );
        if (fromToken) {
          const balance = await getTokenBalance(address, fromToken.id);
          setTokenInBalance(balance);
        }
      }

      if (toCurrency) {
        const toToken = swapTokens.find(
          (item: any) => item.symbol === toCurrency
        );
        if (toToken) {
          const balance = await getTokenBalance(address, toToken.id);
          setTokenOutBalance(balance);
        }
      }
    } catch (e) {
      console.error("Error refreshing balances", e);
    } finally {
      seTokenBalanceLoading(false);
    }
  };

  const handleFromAmountChange = (value: any) => {
    if (isNaN(value)) {
      console.error("Value is not a valid number.");
      return;
    }
    const amount = value;
    setFromAmount(amount);
    setToAmount(value);
  };

  const handleToAmountChange = (value: any) => {
    if (isNaN(value)) {
      console.error("Value is not a valid number.");
      return;
    }

    const amount = value;
    setToAmount(amount);
    setFromAmount(amount);
  };

  const isSwapDisabled = !fromCurrency || !toCurrency || !fromAmount;

  const switchTokens = () => {
    if (toCurrency && fromCurrency) {
      const newFromCurrency = toCurrency;
      const newToCurrency = fromCurrency;

      setFromCurrency(newFromCurrency);
      setToCurrency(newToCurrency);

      setToAmount(fromAmount);
      setFromAmount(toAmount);

      return;
    } else if (fromCurrency) {
      const newToCurrency = fromCurrency;

      setToCurrency(newToCurrency);
      return;
    } else if (toCurrency) {
      const newFromCurrency = toCurrency;
      setToCurrency(newFromCurrency);
      return;
    }
  };

  const onWrap = async () => {
    setLoading(true);
    try {
      let response = null;
      if (fromCurrency === "WSHIDO") {
        response = await unwrapWShido(address, String(fromAmount));
      } else {
        response = await wrapWShido(address, String(fromAmount));
      }

      if (response.hash) {
        await new Promise((resolve) => setTimeout(resolve, 2000));

        setError({
          message: "Success",
          description: `Successfully ${
            fromCurrency === "WSHIDO" ? "unwrapped" : "wrapped"
          } ${fromAmount} ${fromCurrency} to ${toCurrency}`,
          type: "success",
        });
      } else {
        setError({
          message: "Something went wrong",
          type: "error",
        });
      }
      setLoading(false);
    } catch (e: any) {
      setError({
        message: `Error ${
          fromCurrency === "WSHIDO" ? "unwrapping" : "wrapping"
        } token.`,
        description: e.message,
        type: "error",
      });
    } finally {
      setLoading(false);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      refreshBalances();
    }
  };

  const [selectFromTokenModalOpen, setSelectFromTokenModalOpen] =
    useState(false);

  const setFromToken = (token: any) => {
    setFromCurrency(token?.tokenSymbol);
  };

  useEffect(() => {
    if (fromCurrency === "WSHIDO") {
      setToCurrency("SHIDO");
    } else if (fromCurrency === "SHIDO") {
      setToCurrency("WSHIDO");
    } else if (fromCurrency === "USDC (ERC-20)") {
      setToCurrency("USDC (Native)");
    } else if (fromCurrency === "USDC (Native)") {
      setToCurrency("USDC (ERC-20)");
    }
  }, [fromCurrency]);

  const connectKeplr = async () => {
    if (!window.keplr) {
      setError({
        message: "Please install the Keplr extension",
        type: "error",
      });

      return;
    }

    try {
      await window.keplr.enable(OsmosisChainInfo.chainId);
      const offlineSigner = await window.keplr.getOfflineSignerAuto(
        OsmosisChainInfo.chainId
      );
      const accounts = await offlineSigner.getAccounts();

      if (accounts.length === 0) {
        throw new Error("No accounts found");
      }

      setWalletAddress(accounts[0].address);
    } catch (err: any) {
      setError({
        message: "Failed to connect to Keplr",
        type: "error",
        description: err.message,
      });
    }
  };

  useEffect(() => {
    if (window.keplr) {
      connectKeplr();
    }
  }, [window.keplr]);

  const refreshUSDCBalances = async () => {
    seTokenBalanceLoading(true);

    if (["WSHIDO", "SHIDO"].includes(fromCurrency)) {
      seTokenBalanceLoading(false);
      return;
    }

    if (client && walletAddress && window?.keplr) {
      const usdcBalance = await client.getBalance(walletAddress, USDC_DENOM);
      const usdcBalanceConverteed = Number(usdcBalance.amount) / 10 ** 6;
      let usdcErc20Balance = 0;

      try {
        const key = await window.keplr?.getKey(OsmosisChainInfo.chainId);
        const web3 = new Web3(new Web3.providers.HttpProvider(SHIDO_RPC_URL));
        const erc20Contract = new web3.eth.Contract(
          erc20ABI,
          USDC_TOKEN_ADDRESS
        );

        const balance = await erc20Contract.methods
          .balanceOf(key?.ethereumHexAddress)
          .call();

        usdcErc20Balance = Number(balance) / 10 ** 6;
      } catch (e) {
        console.error("Error fetching USDC balance", e);
      } finally {
        seTokenBalanceLoading(false);
      }

      if (fromCurrency === "USDC (Native)") {
        setTokenInBalance(usdcBalanceConverteed);
        setTokenOutBalance(usdcErc20Balance);
      } else {
        setTokenOutBalance(usdcBalanceConverteed);
        setTokenInBalance(usdcErc20Balance);
      }
    }
    seTokenBalanceLoading(false);
  };

  useEffect(() => {
    const loadClient = async () => {
      if (walletAddress) {
        const registry = new Registry();
        registry.register("/shido.erc20.v1.MsgConvertERC20", MsgConvertERC20);

        const offlineSigner = window.keplr?.getOfflineSigner(
          OsmosisChainInfo.chainId
        );

        if (offlineSigner) {
          const client = await SigningStargateClient.connectWithSigner(
            OsmosisChainInfo.rpc,
            offlineSigner,
            {
              gasPrice: GasPrice.fromString(GAS_PRICE),
              registry,
            }
          );

          setClient(client);
        }
      }
    };

    if (walletAddress) {
      loadClient();
    }
  }, [walletAddress]);

  const onConvert = async () => {
    setLoading(true);
    if (window.keplr) {
      try {
        const key = await window.keplr?.getKey(OsmosisChainInfo.chainId);

        if (Number(fromAmount) > Number(tokenInBalance)) {
          throw new Error(
            `Insufficient balance. You have ${tokenInBalance} ${fromCurrency} but trying to convert ${fromAmount}`
          );
        }

        let retryCount = 0;
        const maxRetries = 3;
        let lastError = null;

        while (retryCount < maxRetries) {
          try {
            const sequence = await getAndUpdateSequence(key.bech32Address);
            let protoMsgs;

            if (fromCurrency === "USDC (ERC-20)") {
              protoMsgs = {
                typeUrl: "/shido.erc20.v1.MsgConvertERC20",
                value: MsgConvertERC20.encode({
                  contractAddress: USDC_TOKEN_ADDRESS,
                  amount: `${Number(fromAmount) * 10 ** 6}`,
                  receiver: key.bech32Address,
                  sender: key.ethereumHexAddress,
                }).finish(),
              };
            } else {
              protoMsgs = {
                typeUrl: "/shido.erc20.v1.MsgConvertCoin",
                value: MsgConvertCoin.encode({
                  coin: Coin.fromPartial({
                    denom: USDC_DENOM,
                    amount: `${Number(fromAmount) * 10 ** 6}`,
                  }),
                  receiver: key.ethereumHexAddress,
                  sender: key.bech32Address,
                }).finish(),
              };
            }

            if (Number(fromAmount) > Number(tokenInBalance)) {
              throw new Error(
                `Insufficient balance. You have ${tokenInBalance} ${fromCurrency} but trying to convert ${fromAmount}`
              );
            }

            await new Promise((resolve) => setTimeout(resolve, 2000));

            try {
              const gasUsed = await simulateMsgs(
                OsmosisChainInfo,
                key.bech32Address,
                [protoMsgs],
                [{ denom: "shido", amount: `${Number(fromAmount) * 10 ** 6}` }],
                sequence.toString()
              );

              if (gasUsed) {
                const fee = {
                  amount: [
                    {
                      denom: "shido",
                      amount: `${Number(fromAmount) * 10 ** 6}`,
                    },
                  ],
                  gas: Math.floor(gasUsed * 1.5).toString(),
                };

                await sendMsgs(
                  window.keplr,
                  OsmosisChainInfo,
                  key.bech32Address,
                  [protoMsgs],
                  fee,
                  sequence.toString()
                );

                await new Promise((resolve) => setTimeout(resolve, 5000));
                await refreshUSDCBalances();

                setError({
                  message: "Success",
                  description: `Successfully converted ${fromAmount} ${fromCurrency} to ${toAmount} ${toCurrency}`,
                  type: "success",
                });
                return;
              }
            } catch (simError: any) {
              if (simError.message?.includes("account sequence mismatch")) {
                const expectedSeq = extractSequenceFromError(simError.message);

                if (expectedSeq !== null) {
                  lastKnownSequenceRef.current = expectedSeq;
                  continue;
                }
              }
              throw simError;
            }
          } catch (e: any) {
            if (e.message?.includes("account sequence mismatch")) {
              const expectedSeq = extractSequenceFromError(e.message);
              if (expectedSeq !== null) {
                lastKnownSequenceRef.current = expectedSeq;
                const waitTime = 5000 * Math.pow(2, retryCount);
                await new Promise((resolve) => setTimeout(resolve, waitTime));
                retryCount++;
                lastError = e;
                continue;
              }
            }

            if (e.message?.includes("Request rejected")) {
              throw new Error("Transaction cancelled by user");
            }

            throw e;
          }
          retryCount++;
        }

        if (lastError) {
          throw lastError;
        }
      } catch (e) {
        if (e instanceof Error) {
          setError({
            message: `Failed to convert ${fromCurrency} to ${toCurrency}`,
            type: "error",
            description: e.message,
          });
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <SelectTokenModal
        setToken={setFromToken}
        disabledToken={toCurrency}
        isOpen={selectFromTokenModalOpen}
        onClose={() => setSelectFromTokenModalOpen(false)}
      />

      <Layout
        style={{
          marginTop: "20px",
          backgroundColor: "#062148",
          borderRadius: "12px",
          padding: sm ? "36px" : "12px",
          alignItems: "center",
        }}
      >
        <ConfigProvider
          theme={{
            components: {
              InputNumber: {
                colorBgContainer: "transparent",
                colorBorder: "transparent",
                inputFontSizeSM: 18,
                fontSize: 18,
              },
              Select: {
                fontSizeSM: 18,
                fontSize: 18,
                optionFontSize: 14,
                colorPrimary: "transparent",
                colorPrimaryHover: "transparent",
                controlOutline: "transparent",
              },
            },
          }}
        >
          <Row style={{ justifyContent: "center", marginBottom: "24px" }}>
            <Text size="lg">Wrap/Unwrap</Text>
          </Row>
          <Row
            style={{
              padding: xs ? "14px" : "24px",
              width: xxl ? "720px" : md ? "580px" : xs ? "347px" : "488px",
              backgroundColor: "#173155",
              borderRadius: "20px",
            }}
          >
            {/* /////////////////////////////////////////////////// */}
            <Row style={{ width: "100%" }}>
              <Row
                style={{
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <Text size="sm">
                  Balance:{" "}
                  {tokenBalanceLoading ? (
                    <Skeleton.Button
                      active
                      size="small"
                      style={{ width: "100%" }}
                    />
                  ) : tokenInBalance ? (
                    formatNumberUniversal(tokenInBalance)
                  ) : (
                    "0"
                  )}
                </Text>
                <Button
                  size="small"
                  type="primary"
                  onClick={() =>
                    tokenInBalance &&
                    handleFromAmountChange(Number(tokenInBalance))
                  }
                >
                  Max
                </Button>
              </Row>
              <Row
                style={{
                  justifyContent: "space-between",
                  width: "100%",
                  borderRadius: "12px",
                  backgroundColor: "#062148",
                  padding: "18px 16px",
                  marginTop: "12px",
                }}
              >
                <Col style={{ display: "flex", gap: "12px" }}>
                  <img
                    src={tokenImage}
                    style={{
                      width: "36px",
                      height: "36px",
                      borderRadius: "50%",
                      objectFit: "contain",
                    }}
                  />
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1px",
                    }}
                  >
                    <Text
                      size="xs"
                      style={{ color: "#7C7C82", fontWeight: "400" }}
                    >
                      Swap from
                    </Text>

                    <Button
                      size="small"
                      style={{
                        background: "#062148",
                        color: "#ffffff",
                        border: 0,
                      }}
                      onClick={() => setSelectFromTokenModalOpen(true)}
                    >
                      {fromCurrency}
                      <DownOutlined />
                    </Button>
                  </Col>
                </Col>
                <Col>
                  <InputNumber
                    type="number"
                    size="small"
                    value={fromAmount}
                    onChange={handleFromAmountChange}
                    variant="borderless"
                    controls={false}
                    className="SwapInputNumber"
                    style={{ width: sm ? "150px" : "90px" }}
                  ></InputNumber>
                </Col>
              </Row>
              <Row
                style={{
                  position: "relative",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "12px",
                    borderRadius: "50%",
                    backgroundColor: "#3B87F7",
                    position: "absolute",
                    top: "-14px",
                    cursor: "pointer",
                  }}
                >
                  <img src={ArrowDown} onClick={switchTokens} />
                </div>
              </Row>
              <Row
                style={{
                  justifyContent: "space-between",
                  width: "100%",
                  borderRadius: "12px",
                  backgroundColor: "#062148",
                  padding: "18px 16px",
                  marginTop: "24px",
                }}
              >
                <Col style={{ display: "flex", gap: "12px" }}>
                  <img
                    src={tokenImage}
                    style={{
                      width: "36px",
                      height: "36px",
                      borderRadius: "50%",
                      objectFit: "contain",
                    }}
                  />
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1px",
                    }}
                  >
                    <Text
                      size="xs"
                      style={{ color: "#7C7C82", fontWeight: "400" }}
                    >
                      Swap To
                    </Text>
                    <Text size="md" style={{ fontWeight: "600" }}>
                      {toCurrency}
                    </Text>
                  </Col>
                </Col>
                <Col>
                  <InputNumber
                    type="number"
                    size="small"
                    value={toAmount}
                    onChange={handleToAmountChange}
                    variant="borderless"
                    className="SwapInputNumber"
                    controls={false}
                    style={{ width: sm ? "150px" : "90px" }}
                  ></InputNumber>
                </Col>
              </Row>
              <Row
                style={{
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "12px",
                  width: "100%",
                  marginTop: "12px",
                }}
              >
                <Text size="sm">
                  Balance:{" "}
                  {tokenBalanceLoading ? (
                    <Skeleton.Button
                      active
                      size="small"
                      style={{ width: "100%" }}
                    />
                  ) : tokenOutBalance ? (
                    formatNumberUniversal(tokenOutBalance)
                  ) : (
                    0
                  )}
                </Text>
                <Button
                  size="small"
                  type="primary"
                  onClick={() =>
                    tokenOutBalance &&
                    handleToAmountChange(Number(tokenOutBalance))
                  }
                >
                  Max
                </Button>
              </Row>
            </Row>

            {["SHIDO", "WSHIDO"].includes(fromCurrency) ? (
              <>
                {address ? (
                  <>
                    {fromCurrency && (
                      <Button
                        type="primary"
                        style={{ marginTop: "24px", width: "100%" }}
                        disabled={isSwapDisabled}
                        onClick={() => onWrap()}
                        loading={loading}
                      >
                        {" "}
                        {loading
                          ? "Loading..."
                          : fromCurrency === "WSHIDO"
                          ? "Unwrap"
                          : "Wrap"}
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    type="primary"
                    style={{ marginTop: "24px", width: "100%" }}
                    onClick={() => {
                      open({ view: "Networks" });
                    }}
                  >
                    Connect Wallet
                  </Button>
                )}
              </>
            ) : (
              <>
                {walletAddress ? (
                  <>
                    {fromCurrency && (
                      <Button
                        type="primary"
                        style={{ marginTop: "24px", width: "100%" }}
                        disabled={
                          isSwapDisabled || Number(tokenInBalance) < fromAmount
                        }
                        onClick={() => onConvert()}
                        loading={loading}
                      >
                        {Number(tokenInBalance) < fromAmount
                          ? "Insufficient balance"
                          : "Convert"}
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    type="primary"
                    style={{ marginTop: "24px", width: "100%" }}
                    onClick={() => {
                      connectKeplr();
                    }}
                  >
                    Connect Wallet
                  </Button>
                )}
              </>
            )}
          </Row>

          {(fromCurrency === "USDC (ERC-20)" ||
            fromCurrency === "USDC (Native)") && (
            <KeplrWallet address={walletAddress} />
          )}
        </ConfigProvider>

        <WarningModal
          warningModel={warningModel}
          setWarningModel={setWarningModel}
        />
      </Layout>
    </>
  );
};

export default Wrap;

const KeplrWallet = ({ address }: { address: string | null }) => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  return (
    <Row
      style={{
        padding: "24px",
        borderRadius: "20px",
        border: "1px solid rgba(255, 255, 255, 0.10)",
        background: "#173155",
        marginTop: "16px",
        display: "flex",
        gap: "10px",
        flexDirection: "column",
        width: xxl ? "720px" : md ? "580px" : xs ? "347px" : "488px",
      }}
    >
      <Row style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <img src={address ? check_circle : emergency_home} />
        <Text size="sm" style={{ fontWeight: "600", fontSize: "20px" }}>
          {address
            ? "Keplr wallet is connected"
            : "Please connect Keplr wallet"}
        </Text>
      </Row>
      <Text
        size="sm"
        style={{
          fontWeight: "400",
          fontSize: "16px",
          color: "#ffffff63",
        }}
      >
        WRAP/UNWRAP USDC requires connection with Keplr wallet to process.
      </Text>
    </Row>
  );
};
