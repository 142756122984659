import { Modal, Row } from "antd";
import React from "react";
import Text from "./Text";

interface WarningModalProps {
  warningModel: boolean;
  setWarningModel: React.Dispatch<React.SetStateAction<boolean>>;
}

const WarningModal = (props: WarningModalProps) => {
  const { warningModel, setWarningModel } = props;
  return (
    <Modal
      open={warningModel}
      onOk={() => setWarningModel(false)}
      onCancel={() => setWarningModel(false)}
      footer={false}
      centered
      styles={{
        content: {
          padding: "36px 20px",
          borderRadius: "36px",
          backgroundColor: "#062148",
          border: "1px solid rgba(255, 255, 255, 0.10)",
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <Row
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "24px",
          flexDirection: "column",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="74"
          height="74"
          viewBox="0 0 74 74"
          fill="none"
        >
          <circle cx="37" cy="37" r="37" fill="#F2AB3C" fill-opacity="0.2" />
          <path
            d="M37 57C36.468 57 35.9609 56.9002 35.4788 56.7007C34.9967 56.5012 34.5561 56.2186 34.1571 55.8529L18.1471 39.8429C17.7814 39.4439 17.4988 39.0033 17.2993 38.5212C17.0998 38.0391 17 37.532 17 37C17 36.468 17.0998 35.9526 17.2993 35.4539C17.4988 34.9551 17.7814 34.5229 18.1471 34.1571L34.1571 18.1471C34.5561 17.7481 34.9967 17.4572 35.4788 17.2743C35.9609 17.0914 36.468 17 37 17C37.532 17 38.0474 17.0914 38.5461 17.2743C39.0449 17.4572 39.4771 17.7481 39.8429 18.1471L55.8529 34.1571C56.2519 34.5229 56.5428 34.9551 56.7257 35.4539C56.9086 35.9526 57 36.468 57 37C57 37.532 56.9086 38.0391 56.7257 38.5212C56.5428 39.0033 56.2519 39.4439 55.8529 39.8429L39.8429 55.8529C39.4771 56.2186 39.0449 56.5012 38.5461 56.7007C38.0474 56.9002 37.532 57 37 57ZM37 38.995C37.5653 38.995 38.0391 38.8038 38.4214 38.4214C38.8038 38.0391 38.995 37.5653 38.995 37V29.02C38.995 28.4547 38.8038 27.9809 38.4214 27.5985C38.0391 27.2161 37.5653 27.0249 37 27.0249C36.4347 27.0249 35.9609 27.2161 35.5786 27.5985C35.1962 27.9809 35.005 28.4547 35.005 29.02V37C35.005 37.5653 35.1962 38.0391 35.5786 38.4214C35.9609 38.8038 36.4347 38.995 37 38.995ZM37 44.9801C37.5653 44.9801 38.0391 44.7889 38.4214 44.4065C38.8038 44.0241 38.995 43.5503 38.995 42.985C38.995 42.4198 38.8038 41.946 38.4214 41.5636C38.0391 41.1812 37.5653 40.99 37 40.99C36.4347 40.99 35.9609 41.1812 35.5786 41.5636C35.1962 41.946 35.005 42.4198 35.005 42.985C35.005 43.5503 35.1962 44.0241 35.5786 44.4065C35.9609 44.7889 36.4347 44.9801 37 44.9801Z"
            fill="#F2AB3C"
          />
        </svg>
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Text
            size="lg"
            style={{
              textAlign: "center",
              fontSize: "24px",
              fontWeight: "600",
            }}
          >
            Warning!
          </Text>
          <Text
            size="md"
            style={{
              textAlign: "center",
              color: "#ffffff63",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            Direct Swap (SHIDO to WSHIDO or WSHIDO to SHIDO) is currently under maintenance. Please use swap to WShido and manual unwrap.
          </Text>
        </Row>
      </Row>
    </Modal>
  );
};

export default WarningModal;
