export const api = <T>(url: string, init?: RequestInit): Promise<T> => {
  return fetch(url, init).then(async (response) => {
    const data = await response.json();

    if (!response.ok) {
      if (response.status === 500 && data.message) {
        throw new Error(data.message);
      }
      throw new Error(data.message || response.statusText);
    }

    return data as T;
  });
};
