import { Button, Col, Layout, Row, Tabs, Segmented } from "antd";
import Text from "../components/Text";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { useEffect, useState } from "react";
import YourLiquidityModal from "../components/YourLiquidityModal";
import Web3 from "web3";
import { useAccount } from "wagmi";
import { POSITION_MANAGER_ADDRESS } from "../constants";
import PostionManagerAbi from "../abis/positionManager.json";
import BigNumber from "bignumber.js";
import { erc20ABI } from "wagmi";
import { getPoolAddress, getPoolData } from "../utils/poolFunctions";
import UniswapV3Pool from "@uniswap/v3-core/artifacts/contracts/UniswapV3Pool.sol/UniswapV3Pool.json";
import { fetchTokenImages } from "../api/ApiCalls";
import { Spin } from "antd";
import { getTokenAmounts } from "../utils/poolFunctions";

const executeRPCCall = async <T,>(
  operation: () => Promise<T>,
  maxRetries = 3
): Promise<T> => {
  // add a delay of 500ms before each call
  await new Promise((resolve) => setTimeout(resolve, 500));
  for (let i = 0; i < maxRetries; i++) {
    try {
      return await operation();
    } catch (error: any) {
      if (i === maxRetries - 1) throw error; // If we've used all retries, throw the error
      if (
        error?.message?.includes("header not found") ||
        error?.message?.includes("timeout") ||
        error?.message?.includes("network error")
      ) {
        // Exponential backoff: 1s, 2s, 4s, etc.
        await new Promise((resolve) => setTimeout(resolve, 1000 * 2 ** i));
        continue;
      }
      throw error;
    }
  }
  throw new Error("Max retries reached");
};

const MyPositions = () => {
  const { connector, address } = useAccount();

  const [positions, setPositions] = useState<any>([]);
  const [positionIds, setPositionIds] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [activePosition, setActivePosition] = useState({});

  const updateActivePosition = (position: any) => {
    setActivePosition(position);
  };

  async function getTokenInfo(tokenAddress: any, info: any, web3: Web3) {
    try {
      const tokenContract = new web3.eth.Contract(erc20ABI, tokenAddress);

      return await executeRPCCall(async () => {
        switch (info) {
          case "symbol":
            return await tokenContract.methods.symbol().call();
          case "decimals":
            return await tokenContract.methods.decimals().call();
          default:
            throw new Error("Invalid token info type");
        }
      });
    } catch (error) {
      console.error(`Error fetching token ${info}:`, error);
      return null;
    }
  }

  // create a function to refresh a positions info given its id
  async function refreshPosition(positionId: number) {
    const web3 = new Web3(await connector?.getProvider());
    const contract = new web3.eth.Contract(
      PostionManagerAbi,
      POSITION_MANAGER_ADDRESS
    );

    try {
      const position: any = await executeRPCCall(() =>
        contract.methods.positions(positionId).call()
      );

      const {
        tickLower,
        tickUpper,
        liquidity,
        feeGrowthInside0LastX128,
        feeGrowthInside1LastX128,
        token0,
        token1,
        fee,
      } = position;

      const [
        poolAddress,
        token0Symbol,
        token1Symbol,
        token0Decimals,
        token1Decimals,
      ]: any = await Promise.all([
        executeRPCCall(() => getPoolAddress(address, token0, token1, fee)),
        getTokenInfo(token0, "symbol", web3),
        getTokenInfo(token1, "symbol", web3),
        getTokenInfo(token0, "decimals", web3),
        getTokenInfo(token1, "decimals", web3),
      ]);

      let tokensOwed0 = new BigNumber(0);
      let tokensOwed1 = new BigNumber(0);

      const result: any = await executeRPCCall(() =>
        contract.methods
          .collect({
            tokenId: positionId,
            recipient: address,
            amount0Max: new BigNumber(2).pow(128).minus(1).toFixed(),
            amount1Max: new BigNumber(2).pow(128).minus(1).toFixed(),
          })
          .call()
      );

      tokensOwed0 = new BigNumber(result.amount0);
      tokensOwed1 = new BigNumber(result.amount1);

      const poolContract = new web3.eth.Contract(
        UniswapV3Pool.abi,
        poolAddress
      );
      const poolData = await getPoolData(poolContract);

      const liquidityPercentage = BigNumber(liquidity)
        .div(BigNumber(poolData.liquidity))
        .multipliedBy(BigNumber(100));

      //check if pool is in range
      const isInRange =
        Number(poolData.tick) >= Number(tickLower) &&
        Number(poolData.tick) <= Number(tickUpper);

      // get token0Image and token 1Image

      let token0image = "";
      let token1image = "";

      try {
        const tokenImages = await fetchTokenImages([token0, token1]);
        token0image = tokenImages?.[Web3.utils.toChecksumAddress(token0)];
        token1image = tokenImages?.[Web3.utils.toChecksumAddress(token1)];
      } catch (error) {
        console.error("Error fetching token images", error);
      }

      const [[pooledToken0, pooledToken1]] = await Promise.all([
        getTokenAmounts(
          Number(liquidity),
          BigNumber(poolData.sqrtPriceX96).toString(),
          Number(tickLower),
          Number(tickUpper),
          Number(token0Decimals),
          Number(token1Decimals)
        ),
      ]);

      const positionClosed = pooledToken0 === 0 && pooledToken1 === 0;

      const positionData = {
        tickLower,
        tickUpper,
        liquidity: BigNumber(liquidity),
        feeGrowthInside0LastX128: BigNumber(feeGrowthInside0LastX128),
        feeGrowthInside1LastX128: BigNumber(feeGrowthInside1LastX128),
        tokensOwed0: BigNumber(tokensOwed0),
        tokensOwed1: BigNumber(tokensOwed1),
        token0,
        token1,
        positionId: positionId,
        fee,
        positionIdIndex: positionId,
        liquidityPercentage,
        totalLiquidity: poolData.liquidity,
        token0Symbol,
        token1Symbol,
        token0Decimals,
        token1Decimals,
        poolSqrtPrice: poolData.sqrtPriceX96,
        pooledToken0,
        pooledToken1,
        token0image,
        token1image,
        positionClosed: positionClosed,
        isInRange: isInRange,
        poolTick: poolData.tick,
      };

      setActivePosition(positionData);

      const updatedPositions = positions.map((p: any) =>
        p.positionId === positionId ? positionData : p
      );

      setPositions(updatedPositions);
    } catch (error) {
      console.error("Error in refreshPosition:", error);
      throw error;
    }
  }

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const web3 = new Web3(await connector?.getProvider());
        const contract = new web3.eth.Contract(
          PostionManagerAbi,
          POSITION_MANAGER_ADDRESS
        );

        const numPositions = await executeRPCCall(() =>
          contract.methods.balanceOf(address).call()
        );

        const positionIds: any = await Promise.all(
          Array.from({ length: Number(numPositions) }, (_, i) =>
            executeRPCCall(() =>
              contract.methods.tokenOfOwnerByIndex(address, i).call()
            )
          )
        );

        setPositionIds(positionIds);

        const positionCalls = positionIds.map((id: any) =>
          executeRPCCall(() => contract.methods.positions(id).call())
        );
        const callResponses = await Promise.all(positionCalls);

        const positionInfos = await Promise.all(
          callResponses.map(async (position: any, index) => {
            const {
              tickLower,
              tickUpper,
              liquidity,
              feeGrowthInside0LastX128,
              feeGrowthInside1LastX128,

              token0,
              token1,
              fee,
            } = position;

            const [
              poolAddress,
              token0Symbol,
              token1Symbol,
              token0Decimals,
              token1Decimals,
            ]: any = await Promise.all([
              executeRPCCall(() =>
                getPoolAddress(address, token0, token1, fee)
              ),
              getTokenInfo(token0, "symbol", web3),
              getTokenInfo(token1, "symbol", web3),
              getTokenInfo(token0, "decimals", web3),
              getTokenInfo(token1, "decimals", web3),
            ]);

            let tokensOwed0 = new BigNumber(0);
            let tokensOwed1 = new BigNumber(0);

            const result: any = await executeRPCCall(() =>
              contract.methods
                .collect({
                  tokenId: positionIds[index],
                  recipient: address,
                  amount0Max: new BigNumber(2).pow(128).minus(1).toFixed(),
                  amount1Max: new BigNumber(2).pow(128).minus(1).toFixed(),
                })
                .call()
            );

            tokensOwed0 = new BigNumber(result.amount0);
            tokensOwed1 = new BigNumber(result.amount1);

            const poolContract = new web3.eth.Contract(
              UniswapV3Pool.abi,
              poolAddress
            );
            const poolData = await getPoolData(poolContract);

            const liquidityPercentage = BigNumber(liquidity)
              .div(BigNumber(poolData.liquidity))
              .multipliedBy(BigNumber(100));

            const isInRange =
              Number(poolData.tick) >= Number(tickLower) &&
              Number(poolData.tick) <= Number(tickUpper);

            let token0image = "";
            let token1image = "";

            try {
              const tokenImages = await fetchTokenImages([token0, token1]);
              token0image = tokenImages?.[Web3.utils.toChecksumAddress(token0)];
              token1image = tokenImages?.[Web3.utils.toChecksumAddress(token1)];
            } catch (error) {
              console.error("Error fetching token images", error);
            }

            const [[pooledToken0, pooledToken1]] = await Promise.all([
              getTokenAmounts(
                Number(liquidity),
                BigNumber(poolData.sqrtPriceX96).toString(),
                Number(tickLower),
                Number(tickUpper),
                Number(token0Decimals),
                Number(token1Decimals)
              ),
            ]);

            const positionClosed = pooledToken0 === 0 && pooledToken1 === 0;

            return {
              tickLower,
              tickUpper,
              liquidity: BigNumber(liquidity),
              feeGrowthInside0LastX128: BigNumber(feeGrowthInside0LastX128),
              feeGrowthInside1LastX128: BigNumber(feeGrowthInside1LastX128),
              tokensOwed0: BigNumber(tokensOwed0),
              tokensOwed1: BigNumber(tokensOwed1),
              token0,
              token1,
              positionId: positionIds[index],
              fee,
              positionIdIndex: index,
              liquidityPercentage,
              totalLiquidity: poolData.liquidity,
              token0Symbol,
              token1Symbol,
              token0Decimals,
              token1Decimals,
              poolSqrtPrice: poolData.sqrtPriceX96,
              pooledToken0,
              pooledToken1,
              token0image,
              token1image,
              positionClosed,
              isInRange: isInRange,
              poolTick: poolData.tick,
            };
          })
        );

        setPositions(positionInfos);
      } catch (error) {
        console.error("Error loading positions:", error);
      } finally {
        setLoading(false);
      }
    };

    if (address && connector) {
      loadData();
    }
  }, [connector, address]);

  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();

  return (
    <Layout
      style={{
        padding: sm ? "36px" : "12px",
        backgroundColor: "#062148",
        borderRadius: "12px",
        marginTop: "20px",
        alignItems: "center",
      }}
    >
      <Row style={{ maxWidth: "1240px", width: "100%", gap: "24px" }}>
        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Text size="lg">My Positions</Text>
        </Row>

        <WithPosition
          positions={positions}
          positionIds={positionIds}
          loading={loading}
          refreshPosition={refreshPosition}
          activePosition={activePosition}
          updateActivePosition={updateActivePosition}
        />
      </Row>
    </Layout>
  );
};

export default MyPositions;

interface WithPositionInterface {
  positions: any;
  positionIds: any;
  loading: boolean;
  refreshPosition: any;
  activePosition: any;
  updateActivePosition: any;
}
const WithPosition = ({
  positions,
  positionIds,
  loading,
  refreshPosition,
  activePosition,
  updateActivePosition,
}: WithPositionInterface) => {
  const [open, setOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string | number>("Open Positions");

  return (
    <>
      <Row
        style={{
          width: "100%",
          flexDirection: "column",
          borderRadius: "20px",
          backgroundColor: "#173155",
          padding: "24px",
        }}
      >
        <Row style={{ width: "100%", marginBottom: "10px" }}>
          <Text
            size="sm"
            style={{
              color: "#7C7C82",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Positions overview
          </Text>
        </Row>
        <Segmented
          options={["Open Positions", "Closed Positions"]}
          value={activeTab}
          onChange={setActiveTab}
          size="large"
          style={{ marginBottom: "24px", maxWidth: "285px" }}
        />
        {activeTab === "Open Positions" && (
          <>
            <Row
              style={{
                width: "100%",
                flexDirection: "column",
                borderRadius: "20px",
                backgroundColor: "#173155",
              }}
            >
              {loading ? (
                <Row
                  style={{
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "30px",
                    marginTop: "30px",
                  }}
                >
                  <Spin />
                  <Text
                    size="sm"
                    style={{
                      color: "#B2BBC7",
                      maxWidth: "256px",
                      textAlign: "center",
                    }}
                  >
                    Currently we are fetching your positions, this may take some
                    time...
                  </Text>
                </Row>
              ) : (
                <Row style={{ width: "100%" }}>
                  {positions
                    .filter((position: any) => !position.positionClosed)
                    .map((position: any) => (
                      <LiquidityRow
                        icon1={position.token0image}
                        icon1Name={position.token0Symbol}
                        icon2={position.token1image}
                        icon2Name={position.token1Symbol}
                        setState={setOpen}
                        position={position}
                        updateActivePosition={updateActivePosition}
                        positionIds={positionIds}
                      />
                    ))}
                </Row>
              )}
            </Row>
            <YourLiquidityModal
              open={open}
              setState={setOpen}
              activePosition={activePosition}
              positionIds={positionIds}
              refreshPosition={refreshPosition}
            />
          </>
        )}

        {activeTab === "Closed Positions" && (
          <>
            <Row
              style={{
                width: "100%",
                flexDirection: "column",
                borderRadius: "20px",
                backgroundColor: "#173155",
              }}
            >
              {loading ? (
                <Row
                  style={{
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "30px",
                    marginTop: "30px",
                  }}
                >
                  <Spin />
                  <Text
                    size="sm"
                    style={{
                      color: "#B2BBC7",
                      maxWidth: "256px",
                      textAlign: "center",
                    }}
                  >
                    Currently we are fetching your liquidities, this may take
                    some time...
                  </Text>
                </Row>
              ) : (
                <Row style={{ width: "100%" }}>
                  {positions
                    .filter((position: any) => position.positionClosed)
                    .map((position: any) => (
                      <LiquidityRow
                        icon1={position.token0image}
                        icon1Name={position.token0Symbol}
                        icon2={position.token1image}
                        icon2Name={position.token1Symbol}
                        setState={setOpen}
                        position={position}
                        updateActivePosition={updateActivePosition}
                        positionIds={positionIds}
                      />
                    ))}
                </Row>
              )}
            </Row>
            <YourLiquidityModal
              open={open}
              setState={setOpen}
              activePosition={activePosition}
              positionIds={positionIds}
              refreshPosition={refreshPosition}
            />
          </>
        )}
      </Row>
    </>
  );
};

interface LiquidityRowProps {
  icon1: string;
  icon2: string;
  icon1Name: string;
  icon2Name: string;
  position: any;
  updateActivePosition: any;
  positionIds: any;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
}

const LiquidityRow = (props: LiquidityRowProps) => {
  const {
    icon1,
    icon2,
    icon1Name,
    icon2Name,
    position,
    updateActivePosition,
    setState,
  } = props;

  return (
    <Row
      style={{
        width: "100%",
        marginTop: "16px",
        paddingTop: "16px",
        borderTop: "1px solid rgba(255, 255, 255, 0.10)",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Col style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <div style={{}}>
          <img
            src={icon1}
            style={{
              height: "24px",
              width: "24px",
              borderRadius: "50%",
              objectFit: "contain",
            }}
          />
          <img
            src={icon2}
            style={{
              height: "24px",
              width: "24px",
              position: "relative",
              left: "-5px",
              borderRadius: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <Text size="sm">
          {icon1Name}/{icon2Name}
        </Text>
        <Text size="md" style={{ color: "#3B87F7" }}>
          {Number(position.fee) / 10000}%
        </Text>
      </Col>

      {!position.positionClosed ? (
        <div>
          {position.isInRange === true ? (
            <Text size="md" style={{ color: "rgb(64, 182, 107)" }}>
              In range
            </Text>
          ) : (
            <Text size="md" style={{ color: "yellow" }}>
              Out of range
            </Text>
          )}
        </div>
      ) : (
        ""
      )}

      <Button
        type="primary"
        size="small"
        onClick={() => {
          setState(true);
          updateActivePosition(position);
        }}
        disabled={position.positionClosed}
      >
        {position.positionClosed ? "Closed" : "Manage"}
      </Button>
    </Row>
  );
};
