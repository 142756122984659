// @ts-nocheck

import { ConfigProvider } from "antd";
import App from "./App";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { breakPoints } from "./utils/constants";
import { type Chain } from "viem";
import { defineChain } from "viem";

const projectId = "ac6818d713537310b1b5435748cba3f4";

// 2. Create wagmiConfig
const metadata = {
  name: "Web3Modal",
  description: "Web3Modal",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

export const shido = defineChain({
  id: process.env.REACT_APP_CHAIN_ID,
  name:
    process.env.REACT_APP_CHAIN_ID === "9007"
      ? "Shido Testnet"
      : "Shido Mainnet",
  network: "shido",
  nativeCurrency: {
    decimals: 18,
    name: "Shido",
    symbol: "Shido",
  },
  rpcUrls: {
    default: { http: [process.env.REACT_APP_SHIDO_RPC_URL] },
    public: { http: [process.env.REACT_APP_SHIDO_RPC_URL] },
  },
  blockExplorers: {
    etherscan: { name: "ShidoScan", url: "https://shidoscan.com" },
    default: { name: "ShidoScan", url: "https://shidoscan.com" },
  },
  contracts: {},
});

const chains = [shido];

const wagmiConfig: any = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  // enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains });

const ThemedApp = () => {
  const { screenXS, screenSM, screenMD, screenLG, screenXL, screenXXL } =
    breakPoints;
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  return (
    <ConfigProvider
      theme={{
        token: {
          screenXS,
          screenXSMin: screenXS,
          screenXSMax: screenSM - 1,
          screenSM,
          screenSMMin: screenSM,
          screenSMMax: screenMD - 1,
          screenMD,
          screenMDMin: screenMD,
          screenMDMax: screenLG - 1,
          screenLG,
          screenLGMin: screenLG,
          screenLGMax: screenXL - 1,
          screenXL,
          screenXLMin: screenXL,
          screenXLMax: screenXXL - 1,
          screenXXL,
          screenXXLMin: screenXXL,
        },
        components: {
          Alert: {
            colorErrorBg: "#062148",
            colorSuccessBg: "#062148",
            colorWarningBg: "#062148",
            colorWarningBorder: "rgba(255, 255, 255, 0.10)",
            colorSuccessBorder: "rgba(255, 255, 255, 0.10)",
            colorErrorBorder: "rgba(255, 255, 255, 0.10)",
            borderRadiusLG: 24,
            defaultPadding: 24,
            colorTextHeading: "#FFFFFF",
            colorText: "#ffffff63",
          },
          Button: {
            contentFontSize: sm ? 16 : 14,
            fontWeight: 600,
            controlHeight: 43,
            paddingInline: sm ? 26 : 16,
            colorTextDisabled: "white",
            colorPrimary: "#3B87F7",
            colorPrimaryHover: "#146DF1",
          },
          Modal: {
            contentBg: "#173155",
            colorIcon: "white",
            colorIconHover: "white",
            borderRadiusLG: 12,
          },
          Menu: {
            itemBg: "transparent",
            itemColor: "white",
            itemHoverColor: "#B2BBC7",
            popupBg: "#062148",
          },
          Dropdown: {
            colorBgElevated: "#062148",
            colorText: "white",
            controlItemBgHover: "#7C7C82",
            colorTextDisabled: "white",
          },
          InputNumber: {
            colorBgContainer: "#173155",
            colorBorder: "#173155",
            colorText: "white",
            paddingInline: 18,
            handleHoverColor: "white",
            handleBg: "#84b1fa",
          },
          Table: {
            colorBgContainer: "#173155",
            cellFontSize: 14,
            colorText: "white",
            fontWeightStrong: 500,
            headerSplitColor: "transparent",
            borderColor: "rgba(124, 124, 130, 0.25)",
            cellPaddingBlock: 14,
            headerColor: "#7C7C82",
          },
          Pagination: {
            itemActiveBg: "transparent",
            colorText: "white",
            itemBg: "red",
            colorPrimary: "#3B87F7",
          },
          Select: {
            colorBgContainer: "#062148",
            colorBorder: "#062148",
            colorText: "white",
            colorBgElevated: "#062148",
            optionSelectedBg: "#3B87F7",
            controlHeight: 41,
            optionActiveBg: "#173155",
          },
          Slider: {
            handleColor: "#3B87F7",
            trackBg: "#3B87F7",
            trackHoverBg: "#3B87F7",
            dotActiveBorderColor: "#3B87F7",
            dotBorderColor: "#3B87F7",
            handleActiveColor: "#3B87F7",
            colorPrimaryBorderHover: "#3B87F7",
            handleSize: 12,
            railBg: "#494949",
            railHoverBg: "#494949",
            railSize: 8,
            borderRadiusXS: 5,
          },
          Drawer: {
            colorBgElevated: "#062148",
          },
          Segmented: {
            itemColor: "#7C7C82",
            trackPadding: 6,
            trackBg: "rgb(255 255 255 / 12%)",
            itemSelectedColor: "#fff",
            itemSelectedBg: "#173155",
            itemHoverColor: "#fff",
          },
        },
      }}
    >
      <WagmiConfig config={wagmiConfig}>
        <App />
      </WagmiConfig>
    </ConfigProvider>
  );
};

export default ThemedApp;
