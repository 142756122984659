import { Button, Col, Row } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import React from "react";
import Text from "../../components/Text";
import { useNavigate } from "react-router-dom";
import desktopImage from "../../assets/Svg/swap_img_desktop.svg";
import mobileImage from "../../assets/Svg/swap_img_mobile.svg";

const SwapOverview = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const navigate = useNavigate();
  return (
    <Row
      style={{
        width: "100%",
        padding: md ? "36px" : sm ? "24px" : "16px",
        borderRadius: "12px",
        backgroundColor: "#062148",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: lg ? "row" : "column",
          gap: "20px",
          width: "100%",
        }}
      >
        <div
          style={{
            flex: lg ? "1" : "none",
            width: lg ? "50%" : "100%",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <Text size={"lg"} style={{ fontSize: md ? "32px" : "28px" }}>
            Swap your tokens
          </Text>
          <Text size="md" style={{ color: "#ffffff63", lineHeight: "24px" }}>
            The SHIDO Swap Modal is a simple tool that lets you easily exchange
            your tokens on the SHIDO network. It offers quick and secure
            transactions with low fees. The design is easy to use and works well
            on all devices. Swap your tokens quickly and safely with SHIDO
          </Text>
          <Button
            type="primary"
            style={{ maxWidth: "140px" }}
            onClick={() => {
              navigate("/swap");
            }}
          >
            Go to swap
          </Button>
        </div>
        <div
          style={{
            flex: lg ? "1" : "none",
            width: lg ? "50%" : "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <img
            src={desktopImage}
            className="swap-image"
            style={{ maxWidth: "100%" }}
          />
        </div>
      </div>
    </Row>
  );
};

export default SwapOverview;
