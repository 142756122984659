import "./App.scss";
import Header from "./components/Header";
import { Layout } from "antd";
import Pools from "./pages/Pools";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import SinglePool from "./pages/SinglePool";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import Swap from "./pages/Swap";
import MyPositions from "./pages/MyPositions";
import { useAccount, useConnect, useNetwork, useSwitchNetwork } from "wagmi";
import { useEffect, useState } from "react";
import Home from "./pages/Home";
import { CHAIN_ID } from "./constants";
import { ApolloProvider } from "@apollo/client";
import { GqClient } from "./graphql";
import Wrap from "./pages/Wrap";
import banner_element from "../src/assets/banner_element.png";
import { ErrorProvider } from "./contexts/ErrorContext";
import FAQ from "./pages/FAQ";
import useShidoAutoConnect from "./hooks/useShidoAutoConnect";

interface BannerElementProps {
  BannerElementClass: string;
}

function App() {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { chains, error, isLoading, pendingChainId, switchNetwork } =
    useSwitchNetwork();
  useShidoAutoConnect();

  useEffect(() => {
    if (!pendingChainId && !isLoading) {
      if (chain?.id !== CHAIN_ID) {
        switchNetwork?.(CHAIN_ID);
      }
    }
  }, [chain, switchNetwork, pendingChainId, isLoading]);

  const [IsPoolsOverlayOpen, setIsPoolsOverlayOpen] = useState(
    process.env.REACT_APP_CHAIN_ID === "9007" ? true : false
  );
  const BannerElement: React.FC<BannerElementProps> = ({
    BannerElementClass,
  }) => {
    return (
      <div className={`banner-element ${BannerElementClass}`}>
        <div className="img-wrap">
          <img src={banner_element} />
          <img src={banner_element} />
        </div>
      </div>
    );
  };

  return (
    <ErrorProvider>
      <Layout
        className="App"
        style={{
          backgroundColor: "#041630",
          position: "relative",
          minHeight: "100vh",
          padding: sm ? "20px" : "8px",
          overflow: IsPoolsOverlayOpen ? "hidden" : "visible",
          height: IsPoolsOverlayOpen ? "100vh" : "auto",
          maxWidth: "1460px",
          margin: "auto",
        }}>
        <ApolloProvider client={GqClient}>
          <Router>
            <Header />

            <Routes>
              {address && (
                <Route path="/my-positions" element={<MyPositions />} />
              )}
              <Route path="/pool/:poolAddress" element={<SinglePool />} />
              <Route path="/swap" element={<Swap />} />
              <Route path="/wrap-unwrap" element={<Wrap />} />
              <Route path="/pools" element={<Pools />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="*" element={<Home />} />
            </Routes>

            <div
              className={
                "pools-overlay-container " +
                (IsPoolsOverlayOpen ? "IsPoolsOverlayOpen" : "")
              }>
              <div className="banner-wrap">
                <BannerElement BannerElementClass="TickerMoveRight Banner1" />
                <BannerElement BannerElementClass="TickerMoveLeft Banner2" />
                <BannerElement BannerElementClass="TickerMoveRight Banner3" />
                <BannerElement BannerElementClass="TickerMoveLeft Banner4" />
                <BannerElement BannerElementClass="TickerMoveRight Banner5" />
                <BannerElement BannerElementClass="TickerMoveLeft Banner6" />
              </div>

              <div className="confirm-modal-container">
                <h2>This is a Testnet Environment</h2>
                <p>
                  You are about to enter a Testnet environment. Testnets are
                  parallel blockchain networks that replicate the functionality
                  of a mainnet. So you can't trade with real tokens.
                </p>
                <div className="btn-wrap">
                  <button
                    className="btn"
                    onClick={() => {
                      setIsPoolsOverlayOpen(false);
                    }}>
                    <p>Enter the Testnet Environment</p>
                  </button>
                </div>
              </div>
            </div>
          </Router>
        </ApolloProvider>
      </Layout>
    </ErrorProvider>
  );
}

export default App;
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
