import { Collapse, CollapseProps, ConfigProvider, Row } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import React from "react";
import Text from "../Text";
import add from "../../assets/FAQ/add.png";
import remove from "../../assets/FAQ/remove.png";

interface FAQBoxProps {
  title: string;
  items: CollapseProps["items"];
}

const FAQBox = (prop: FAQBoxProps) => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const { title, items } = prop;

  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            contentBg: "transparent",
            headerBg: "transparent",
            colorBorder: "transparent",
            colorText: "#ffffff63",
            colorTextHeading: "#fff",
            fontSize: 16,
          },
        },
      }}
    >
      <Row
        style={{
          width: "100%",
          padding: sm ? "24px" : "36px",
          borderRadius: "12px",
          backgroundColor: "#062148",

          display: "flex",
          flexDirection: "column",
        }}
      >
        <Text
          size="sm"
          style={{ fontSize: "24px", fontWeight: "700", marginBottom: "24px" }}
        >
          {title}
        </Text>
        <Collapse
          items={items}
          defaultActiveKey={["1"]}
          expandIconPosition="right"
          expandIcon={customExpandIcon}
        />
      </Row>
    </ConfigProvider>
  );
};

export default FAQBox;

const customExpandIcon = (panelProps: any) => {
  return panelProps.isActive ? <img src={remove} /> : <img src={add} />;
};
