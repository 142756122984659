import {
  Button,
  Col,
  ConfigProvider,
  InputNumber,
  MenuProps,
  Modal,
  Row,
  Select,
  Switch,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { getTokens } from "../api/ApiCalls";
import { PlusOutlined } from "@ant-design/icons";
import close from "../assets/Svg/Close.svg";
import back from "../assets/Svg/Back.svg";
import { Pie, PieConfig } from "@ant-design/plots";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { erc20ABI, useAccount, useChainId } from "wagmi";
import { computePoolAddress, encodeSqrtRatioX96 } from "@uniswap/v3-sdk";
import {
  createAndMintPool,
  createPosition,
  getPoolAddress,
  getPoolData,
  getPositionManagerAllowance,
  getPositionManagerApproval,
  getPrice,
  getTokenBalance,
  getTokenDecimals,
} from "../utils/poolFunctions";

import BigNumber from "bignumber.js";
import Web3 from "web3";
import UniswapV3Pool from "@uniswap/v3-core/artifacts/contracts/UniswapV3Pool.sol/UniswapV3Pool.json";
import { fromReadableAmount } from "../utils/tradeUtils";
import { delayExecution, formatNumberUniversal } from "../utils";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import SelectTokenModal from "./SelectTokenModal";
import PriceRange from "./HomeComponents/PriceRange";
import { waitForTransaction } from "@wagmi/core";
import { useError } from "../contexts/ErrorContext";
import Text from "./Text";
import { useNavigate } from "react-router-dom";
import { FACTORY_ADDRESS } from "../constants";
import { Token } from "@shidoglobal/sdk-core";

const CreatePool = ({ refetch }: any) => {
  const [loading, setLoading] = useState(false);
  const [loadingPool, setLoadingPool] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabNumber, setTabNumber] = useState<number>(2);
  const [fee, setFee] = useState<number>(500);

  const [tokenOptions, setTokenOptions] = useState<any[]>([]);
  const [selectedToken, setSelectedToken] = useState<any[]>([]);
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const { connector, address, isConnected } = useAccount();

  const { open } = useWeb3Modal();
  const [exchangeRate, setExchangeRate] = useState<any>({
    token0: 1,
    token1: 1,
  });
  const [token0Balance, setToken0Balance] = useState<any>(0);
  const [token1Balance, setToken1Balance] = useState<any>(0);
  const [token0DepositAmount, setToken0DepositAmount] = useState<any>(1);
  const [token1DepositAmount, setToken1DepositAmount] = useState<any>(1);
  const [token0Allowance, setToken0Allowance] = useState<BigNumber>(
    BigNumber(0)
  );
  const [token1Allowance, setToken1Allowance] = useState<BigNumber>(
    BigNumber(0)
  );
  const [tokenModelOpen, setTokenModelOpen] = useState(false);
  const [lowPriceRange, setLowPriceRange] = useState<null | number>(null);
  const [highPriceRange, setHighPriceRange] = useState<null | number>(null);
  const [fullPriceRange, setFullPriceRange] = useState(true);
  const natigate = useNavigate();

  const onSwitchChange = (checked: boolean) => {
    if (checked) {
      setLowPriceRange(null);
      setHighPriceRange(null);
    }
    setFullPriceRange(checked);
  };

  const { setError } = useError();
  const handlelowPriceRangeChange = (price: number | null) => {
    setLowPriceRange(price);
  };

  const handleHighPriceRangeChange = (price: number | null) => {
    setHighPriceRange(price);
  };

  const chainId = useChainId();

  const JSBI = require("jsbi");

  const [pool, setPool] = useState<String | null>(null);

  const PlotColors = ["#3B87F7", "#F2AB3C", "#51f542", "#f58a42", "#f58a42"];

  const handleCreatePool = async () => {
    if (pool === null) {
      if (
        BigNumber(exchangeRate.token1).isGreaterThan(
          BigNumber(token0DepositAmount)
        ) ||
        BigNumber(exchangeRate.token0).isGreaterThan(
          BigNumber(token1DepositAmount)
        )
      ) {
        setError({
          message: "Invalid deposit ratio",
          description:
            "The deposit amounts do not match the current exchange rate ratio",
          type: "error",
        });

        setLoading(false);
        return;
      }
    }
    setLoading(true);
    try {
      let erc20Address = [
        selectedToken[0].contractAddress,
        selectedToken[1].contractAddress,
      ];
      erc20Address = erc20Address.sort();

      const token0Address = erc20Address[0];
      const token1Address = erc20Address[1];

      let [token0Decimals, token1Decimals] = await Promise.all([
        getTokenDecimals(address, token0Address),
        getTokenDecimals(address, token1Address),
      ]);

      if (token0Address !== selectedToken[0].contractAddress) {
        [token0Decimals, token1Decimals] = [token1Decimals, token0Decimals];
      }

      let token0Amount: any = fromReadableAmount(
        BigNumber(token0DepositAmount),
        Number(token0Decimals)
      );

      let token1Amount: any = fromReadableAmount(
        BigNumber(token1DepositAmount),
        Number(token1Decimals)
      );

      if (token0Address !== selectedToken[0].contractAddress) {
        [token0Amount, token1Amount] = [token1Amount, token0Amount];
        [token0Decimals, token1Decimals] = [token1Decimals, token0Decimals];
      }

      const sqrtPriceX96 = encodeSqrtRatioX96(
        JSBI.BigInt(token1Amount),
        JSBI.BigInt(token0Amount)
      );

      const web3 = new Web3(await connector?.getProvider());

      let res;

      const token0Data = {
        token0Address,
        token0Decimals,
        token0Amount,
      };

      const token1Data = {
        token1Address,
        token1Decimals,
        token1Amount,
      };

      if (pool === null) {
        res = await createAndMintPool(
          address,
          fee ? fee : 0,
          token0Data,
          token1Data,
          sqrtPriceX96,
          web3,
          chainId,
          lowPriceRange,
          highPriceRange
        );

        if (res?.transactionHash) {
          await delayExecution(5);
          try {
            refetch();
          } catch (err: any) {
            setError({
              message: err.message,
              type: "error",
            });
          }
          setIsModalOpen(false);

          setError({
            message: "Pool Created Successfully",
            description: `Liquidity pool for ${selectedToken[0].symbol} and ${selectedToken[1].symbol} created successfully`,
            type: "success",
          });
          setSelectedToken([]);
          setTabNumber(2);

          const tokenA = new Token(
            Number(chainId),
            selectedToken[0].contractAddress,
            Number(selectedToken[0].decimals),
            selectedToken[0].symbol,
            selectedToken[0].name
          );

          const tokenB = new Token(
            Number(chainId),
            selectedToken[1].contractAddress,
            Number(selectedToken[1].decimals),
            selectedToken[1].symbol,
            selectedToken[1].name
          );

          const newPoolAddress = computePoolAddress({
            factoryAddress: FACTORY_ADDRESS,
            fee: fee,
            tokenA,
            tokenB,
          });
          natigate(`/pool/${newPoolAddress.toLocaleLowerCase()}`);
        } else {
          setError({
            message: "Pool Refresh Failed",
            description: "Error refetching pools",
            type: "error",
          });
        }
      } else {
        res = await createPosition(
          address,
          fee ? fee : 0,
          token0Address,
          token1Address,
          web3,
          token0Amount,
          token1Amount,
          lowPriceRange,
          highPriceRange
        );

        if (res?.transactionHash) {
          setError({
            message: "Position added  Successfully",
            description: ``,
            type: "success",
          });

          natigate("/my-positions");
        } else {
          setError({
            message: "Failed to add position",

            type: "error",
          });
        }
      }
    } catch (error: any) {
      setError({
        message: "Pool Creation Failed",
        description: error.message,
      });
    }

    setLoading(false);
  };

  const checkValid = () => {
    if (!fee) {
      setError({
        message: "No Fee Available",
        description: "Please enter Fee Value",
        type: "error",
      });
    } else {
      setTabNumber(3);
    }
  };
  const handleInputFee = (value: number) => {
    setFee(value);
  };
  const showModal = () => {
    setIsModalOpen(true);
    setTabNumber(2);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setTabNumber(2);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setTabNumber(2);
  };

  const handleDropDown = (token: any) => {
    if (selectedToken.length === 2) {
      return;
    }

    const existingToken = selectedToken.find(
      (selected) => selected.contractAddress === token.contractAddress
    );

    if (!existingToken) {
      const newToken: SelectedToken = {
        name: token.tokenName,
        contractAddress: token.contractAddress,
        image: token.image,
        symbol: token.tokenSymbol,
        decimals: token.decimal,
      };

      setSelectedToken((prevSelectedTokens) => [
        ...prevSelectedTokens,
        newToken,
      ]);
    } else {
      setError({
        message: "Invalid input",
        description: "This token is already selected",
        type: "error",
      });
    }
  };

  const handleRemoveToken = (index: number) => {
    setSelectedToken((prevSelectedToken) => {
      const updatedSelectedToken = [...prevSelectedToken];
      updatedSelectedToken.splice(index, 1);
      return updatedSelectedToken;
    });
  };

  const config: PieConfig = {
    pieStyle: {
      lineWidth: 0,
    },
    color: PlotColors,
    appendPadding: 10,
    data: selectedToken.length ? selectedToken : [],
    height: 150,
    width: 150,
    angleField: "value",
    colorField: "name",
    radius: 0.9,
    innerRadius: 0.75,
    label: false,
    legend: false,
    statistic: { title: false, content: false },
  };

  useEffect(() => {
    // fetchTokens();
    const loadData = async () => {
      const tokens: any = await getTokens();
      setTokenOptions(tokens);
    };

    loadData();
  }, []);

  const getTokenBalances = async () => {
    try {
      if (selectedToken[0] && selectedToken[0].contractAddress) {
        const balance = await getTokenBalance(
          address,
          selectedToken[0].contractAddress
        );
        setToken0Balance(balance);
      } else {
        setToken0Balance(0);
      }

      if (selectedToken[1] && selectedToken[1].contractAddress) {
        const balance = await getTokenBalance(
          address,
          selectedToken[1].contractAddress
        );
        setToken1Balance(balance);
      } else {
        setToken1Balance(0);
      }
    } catch (error) {
      console.log(error);
      setToken0Balance(0);
      setToken1Balance(0);
    }
  };

  useEffect(() => {
    getTokenBalances();
  }, [selectedToken]);

  const allowConfirmPoolCreation = () => {
    return (
      loading ||
      token0Allowance.isEqualTo(BigNumber(0)) ||
      token0Allowance.isLessThan(BigNumber(token0DepositAmount)) ||
      token1Allowance.isEqualTo(BigNumber(0)) ||
      token1Allowance.isLessThan(BigNumber(token1DepositAmount)) ||
      BigNumber(token1DepositAmount).isEqualTo(0) ||
      BigNumber(token0Balance).isLessThan(token0DepositAmount) ||
      BigNumber(token1Balance).isLessThan(token1DepositAmount)
    );
  };

  const checkTokenAllowance = async (tokenAddress: string) => {
    if (tokenAddress) {
      const allowance = await getPositionManagerAllowance(
        address,
        tokenAddress
      );

      return allowance;
    }
  };

  const getToken0Allowance = async () => {
    if (selectedToken && selectedToken[0]) {
      const allowance = await getPositionManagerAllowance(
        address,
        selectedToken[0].contractAddress
      );

      setToken0Allowance(allowance);
    }
  };

  const getToken1Allowance = async () => {
    if (selectedToken && selectedToken[1]) {
      const allowance = await getPositionManagerAllowance(
        address,
        selectedToken[1].contractAddress
      );

      setToken1Allowance(allowance);
    }
  };

  useEffect(() => {
    if (
      token0Allowance.isEqualTo(BigNumber(0)) ||
      token0Allowance.isGreaterThan(BigNumber(token0DepositAmount))
    ) {
      getToken0Allowance();
    }
  }, [selectedToken[0], token0DepositAmount]);

  useEffect(() => {
    if (
      token1Allowance.isEqualTo(BigNumber(0)) ||
      token1Allowance.isGreaterThan(BigNumber(token1DepositAmount))
    ) {
      getToken1Allowance();
    }
  }, [selectedToken[1], token1DepositAmount]);

  const checkPoolExistence = async () => {
    setLoadingPool(true);

    const poolAddress: any = await getPoolAddress(
      address,
      selectedToken[0].contractAddress,
      selectedToken[1].contractAddress,
      fee
    );

    if (poolAddress !== "0x0000000000000000000000000000000000000000") {
      const web3 = new Web3(await connector?.getProvider());
      setPool(poolAddress);

      const poolContract = new web3.eth.Contract(
        UniswapV3Pool.abi,
        poolAddress
      );
      const poolData = await getPoolData(poolContract);

      // get token 0 decimals and token 1 decimals
      const token0Contract = new web3.eth.Contract(erc20ABI, poolData.token0);
      const token1Contract = new web3.eth.Contract(erc20ABI, poolData.token1);

      const [token0Decimals, token1Decimals] = await Promise.all([
        token0Contract.methods.decimals().call(),
        token1Contract.methods.decimals().call(),
      ]);

      let prices = await getPrice({
        sqrtPriceX96: poolData.sqrtPriceX96,
        Decimal0: Number(token1Decimals),
        Decimal1: Number(token0Decimals),
      });

      if (
        String(selectedToken[0].contractAddress).toLocaleLowerCase() !==
        String(poolData.token0).toLocaleLowerCase()
      ) {
        let temp = prices.token1;
        prices.token1 = prices.token0;
        prices.token0 = temp;
      }

      setExchangeRate(prices);
    } else {
      setPool(null);
    }
    setLoadingPool(false);
  };

  useEffect(() => {
    if (
      tabNumber === 3 &&
      selectedToken &&
      selectedToken[0] &&
      selectedToken[1]
    ) {
      checkPoolExistence();
    }
  }, [tabNumber, selectedToken[0], selectedToken[1]]);

  useEffect(() => {
    if (
      tabNumber === 2 &&
      selectedToken &&
      selectedToken[0] &&
      selectedToken[1]
    ) {
      checkPoolExistence();
    }
  }, [tabNumber, fee, selectedToken[0], selectedToken[1]]);

  const handleInputChange = (value: any, index: any) => {
    if (index === 0) {
      setToken0DepositAmount(value);
      if (value) {
        const calculatedToken1 = formatNumberUniversal(
          String(value * exchangeRate.token0)
        );

        setToken1DepositAmount(calculatedToken1);
      } else {
        setToken1DepositAmount(0);
      }
    } else if (index === 1) {
      setToken1DepositAmount(value);
      if (value) {
        const calculatedToken0 = formatNumberUniversal(
          String(value * exchangeRate.token1)
        );
        setToken0DepositAmount(calculatedToken0);
      } else {
        setToken0DepositAmount(0);
      }
    }
  };

  useEffect(() => {
    if (exchangeRate.token0 && exchangeRate.token1 && token0DepositAmount) {
      const calculatedToken1 = token0DepositAmount * exchangeRate.token0;

      setToken1DepositAmount(calculatedToken1);

      return;
    }

    if (exchangeRate.token0 && exchangeRate.token1 && token1DepositAmount) {
      const calculatedToken0 = token1DepositAmount * exchangeRate.token1;

      setToken0DepositAmount(calculatedToken0);

      return;
    }
  }, [exchangeRate]);

  const openTokenModel = () => {
    setTokenModelOpen(true);
  };

  const closeTokenModel = () => {
    setTokenModelOpen(false);
  };

  // set tokens allowance

  const approveTokenAllowance = async (tokenAddress: string) => {
    setLoading(true);

    try {
      const depositAmount =
        tokenAddress === selectedToken[0].contractAddress
          ? token0DepositAmount
          : token1DepositAmount;

      const maxApprovalValue = new BigNumber(
        "115792089237316195423570985008687907853269984665640564039457.584007913129639935"
      );
      const scaledValue = maxApprovalValue
        .multipliedBy(new BigNumber(10).pow(18))
        .integerValue();

      const approvalResult = await getPositionManagerApproval(
        address,
        tokenAddress,
        scaledValue
      );

      if (approvalResult && approvalResult.transactionHash) {
        const transactionReceipt = await waitForTransaction({
          confirmations: 3,
          hash: approvalResult.transactionHash,
        });

        if (transactionReceipt.status !== "success") {
          setError({
            message: "Failed to approve the transaction",
            description: "Please try again",
            type: "error",
          });
        } else {
          const currentAllowance = await checkTokenAllowance(tokenAddress);
          if (currentAllowance?.isGreaterThanOrEqualTo(depositAmount)) {
            tokenAddress === selectedToken[0].contractAddress
              ? setToken0Allowance(currentAllowance)
              : setToken1Allowance(currentAllowance);
          }
        }
      }
    } catch (error: any) {
      setError({
        message: "Failed to approve the transaction",
        description: error.message,
        type: "error",
      });
    }

    setLoading(false);
  };

  return (
    <>
      <Button
        onClick={() => {
          address ? showModal() : open({ view: "Networks" });
        }}
        type="primary"
        style={{ width: "100%" }}
      >
        {!pool ? "Create New Pool" : "Create New Position"}
      </Button>
      <Modal
        styles={{ content: { padding: sm ? "36px" : "20px" } }}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        closable={false}
        footer={false}
        width={sm ? 500 : "100%"}
      >
        <Row
          style={{
            width: "100%",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {tabNumber !== 2 && (
            <img
              src={back}
              style={{
                cursor: "pointer",
                position: "absolute",
                left: 0,
                top: "5px",
              }}
              onClick={() => {
                setTabNumber(tabNumber - 1);
              }}
            />
          )}
          <h5
            style={{
              fontSize: "24px",
              color: "white",
              fontWeight: "700",
              margin: 0,
              top: "5px",
            }}
          >
            {!pool ? "Create New Pool" : "Create New Position"}
          </h5>

          <img
            src={close}
            onClick={handleCancel}
            style={{ cursor: "pointer", position: "absolute", right: 0 }}
          />
        </Row>
        <Row style={{ justifyContent: "center" }}>
          {tabNumber === 2 && (
            <Row
              style={{
                flexDirection: "column",
                justifyContent: "center ",
                alignItems: "center",
                width: "100%",
              }}
            >
              <h6
                style={{
                  color: "#ffffff63",
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: 0,
                  marginBottom: "36px",
                }}
              >
                Step 1 / 3 - Set token ratios
              </h6>

              <SelectTokenModal
                setToken={handleDropDown}
                disabledToken={
                  selectedToken && selectedToken.length > 0
                    ? selectedToken[0]
                    : null
                }
                isOpen={tokenModelOpen}
                onClose={closeTokenModel}
              />

              {selectedToken.map((token, index) => (
                <Row
                  style={{
                    display: "flex",
                    gap: "12px",
                    width: "100%",
                    marginBottom: "12px",
                  }}
                  key={index}
                >
                  <Col
                    style={{
                      display: "flex",
                      backgroundColor: "#062148",
                      flexGrow: 1,
                      borderRadius: "8px",
                      padding: "6px 6px 6px 12px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={token.image}
                        width={30}
                        height={30}
                        style={{ objectFit: "contain" }}
                      />

                      <h6
                        style={{
                          margin: 0,
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "white",
                        }}
                      >
                        {token.name}
                      </h6>
                    </Col>
                  </Col>
                  <Col>
                    {" "}
                    <img
                      src={close}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleRemoveToken(index);
                      }}
                    />
                  </Col>
                </Row>
              ))}

              <Button
                onClick={openTokenModel}
                type="primary"
                icon={<PlusOutlined />}
                style={{ width: "100%", marginTop: "" }}
                disabled={selectedToken.length >= 2}
              >
                Add new token
              </Button>

              <Col
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "36px",
                }}
              >
                <h6
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "white",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  Set Swap Fee
                </h6>
                <ConfigProvider
                  theme={{
                    components: {
                      InputNumber: {
                        colorBgContainer: "#062148",
                        colorBorder: "#062148",
                      },
                    },
                  }}
                >
                  <Select
                    value={fee}
                    defaultValue={500}
                    onChange={(value) => handleInputFee(value)}
                    style={{ width: "100px" }}
                  >
                    <Select.Option value={500}>0.05%</Select.Option>
                    <Select.Option value={3000}>0.3%</Select.Option>
                    <Select.Option value={10000}>1%</Select.Option>
                  </Select>
                </ConfigProvider>
              </Col>

              {pool !== null && (
                <h6
                  style={{
                    margin: "0px",
                    padding: "0px",
                    color: "red",
                    width: "100%",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  This pool already exists
                </h6>
              )}
              {address && isConnected ? (
                <Button
                  type="primary"
                  style={{ marginTop: "16px", width: "100%" }}
                  onClick={checkValid}
                  disabled={selectedToken.length < 2}
                >
                  {selectedToken.length < 2
                    ? "Select 2 tokens"
                    : pool !== null
                    ? "Add liquidity"
                    : "Next"}
                </Button>
              ) : (
                <Button
                  type="primary"
                  style={{ marginTop: "16px", width: "100%" }}
                  disabled={true}
                >
                  Connect Wallet
                </Button>
              )}
            </Row>
          )}
          {tabNumber === 3 && (
            <Row
              style={{
                flexDirection: "column",
                justifyContent: "center ",
                alignItems: "center",
                width: "100%",
              }}
            >
              <h6
                style={{
                  color: "#ffffff63",
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: 0,
                  marginBottom: "24px",
                }}
              >
                Step 2 / 3 - Amount to add to the pool
              </h6>

              {pool === null && !loadingPool && (
                <>
                  <p
                    style={{
                      color: "white",
                      margin: "0",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    This pool must be initialized before you can add liquidity.
                    To initialize, select a starting price for the pool. Then,
                    enter your liquidity price range and deposit amount. Gas
                    fees will be higher than usual due to the initialization
                    transaction.
                  </p>

                  <div style={{ width: "100%" }}>
                    <p
                      style={{
                        fontSize: "12px",
                        color: "#ffffff63",
                        fontWeight: "400",
                        margin: 0,
                        marginTop: "16px",
                      }}
                    >
                      Initial token price
                    </p>
                    <InputNumber
                      type="number"
                      style={{
                        backgroundColor: "#394e6c",
                        borderRadius: "6px",
                        padding: "6px 12px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "start",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "White",
                        width: "100%",
                      }}
                      value={exchangeRate.token0}
                      // defaultValue={1}
                      size="small"
                      onChange={(value) => {
                        if (value !== null) {
                          setExchangeRate({
                            token0: value,
                            token1: 1 / value,
                          });
                        } else {
                          setExchangeRate({
                            token0: 1,
                            token1: 1,
                          });
                        }
                      }}
                    />
                  </div>
                </>
              )}

              <Row
                style={{
                  marginTop: "32px",
                  marginBottom: "36px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    marginBottom: "15px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    display: "flex",
                  }}
                >
                  <Text
                    size="sm"
                    style={{
                      fontWeight: "500",
                      marginRight: "8px",
                    }}
                  >
                    Use full price range
                  </Text>

                  <Switch onChange={onSwitchChange} checked={fullPriceRange} />
                </div>

                <PriceRange
                  handlelowPriceRangeChange={handlelowPriceRangeChange}
                  lowPriceRange={lowPriceRange}
                  handleHighPriceRangeChange={handleHighPriceRangeChange}
                  highPriceRange={highPriceRange}
                  token0Symbol={selectedToken[0].symbol}
                  token1Symbol={selectedToken[1].symbol}
                  fee={fee}
                  token0Decimals={Number(selectedToken[0].decimals)}
                  token1Decimals={Number(selectedToken[1].decimals)}
                  fullPriceRange={fullPriceRange}
                />
              </Row>

              {selectedToken.map((token, index) => (
                <Row
                  style={{
                    justifyContent: "space-between",
                    backgroundColor: "#062148",
                    borderRadius: "8px",
                    padding: "8px 12px",
                    marginTop: "12px",
                    width: "100%",
                    alignItems: "center",
                  }}
                  key={index}
                >
                  <Col style={{ display: "flex", gap: "10px", flexGrow: 1 }}>
                    <img
                      src={token.image}
                      style={{
                        width: "36px",
                        height: "36px",
                        borderRadius: "50%",
                        objectFit: "contain",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <h5
                        style={{
                          margin: 0,
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "white",
                        }}
                      >
                        {token.name}
                      </h5>
                    </div>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <h6
                      style={{
                        margin: 0,
                        color: "#ffffff63",
                        fontSize: "12px",
                        fontWeight: "400",
                        alignSelf: "flex-end",
                      }}
                    >
                      Balance:
                      {index === 0
                        ? formatNumberUniversal(token0Balance)
                        : formatNumberUniversal(token1Balance)}{" "}
                      {token.name}
                    </h6>

                    <InputNumber
                      type="number"
                      style={{
                        backgroundColor: "#173155",
                        borderRadius: "6px",
                        padding: "6px 12px",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "White",
                        width: "160px",
                      }}
                      value={
                        index === 0 ? token0DepositAmount : token1DepositAmount
                      }
                      defaultValue={1}
                      size="small"
                      onChange={(value) => {
                        if (value !== null && index === 0) {
                          handleInputChange(value, 0);
                        } else if (value !== null && index === 1) {
                          handleInputChange(value, 1);
                        }
                      }}
                    />
                  </Col>
                </Row>
              ))}

              {(token0Allowance.isEqualTo(BigNumber(0)) ||
                token0Allowance.isLessThan(BigNumber(token0DepositAmount))) && (
                <Button
                  disabled={loading}
                  type="primary"
                  style={{ width: "100%", marginTop: "36px" }}
                  onClick={() =>
                    approveTokenAllowance(selectedToken[0].contractAddress)
                  }
                >
                  Approve {selectedToken[0].name}
                </Button>
              )}

              {(token1Allowance.isEqualTo(BigNumber(0)) ||
                token1Allowance.isLessThan(BigNumber(token1DepositAmount))) && (
                <Button
                  disabled={loading}
                  type="primary"
                  style={{ width: "100%", marginTop: "36px" }}
                  onClick={() =>
                    approveTokenAllowance(selectedToken[1].contractAddress)
                  }
                >
                  Approve {selectedToken[1].name}
                </Button>
              )}

              <Button
                type="primary"
                style={{ width: "100%", marginTop: "36px" }}
                onClick={() => {
                  setTabNumber(4);
                }}
                disabled={allowConfirmPoolCreation()}
              >
                Confirm
              </Button>
            </Row>
          )}
          {tabNumber === 4 && (
            <Row style={{ width: "100%", justifyContent: "center" }}>
              <h6
                style={{
                  color: "#ffffff63",
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: 0,
                  marginBottom: "36px",
                }}
              >
                Step 3 / 3 - Confirm Pool Ratio and Token Amount
              </h6>
              <Row
                className="new-pool-step3"
                style={{
                  width: "100%",
                  paddingBottom: "24px",
                  borderBottom: "1px solid #7C7C82",
                  justifyContent: "space-between",
                }}
              >
                <Col style={{}} className="pie-in-create-pool-model">
                  <div style={{ width: "150px", height: "150px" }}>
                    <Pie {...config} />
                  </div>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    flexGrow: 1,
                    width: sm ? "230px" : "100%",
                  }}
                >
                  <Col
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="token-price-tag">Token</p>
                    <p className="token-price-tag">Amount</p>
                  </Col>
                  {selectedToken.map((token, index) => (
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      key={index}
                    >
                      <Col
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "18px",
                            height: "18px",
                            borderRadius: "50%",
                            backgroundColor: PlotColors[index],
                          }}
                        ></div>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "white",
                            margin: 0,
                          }}
                        >
                          {token.name}
                        </p>
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <h5
                          style={{
                            margin: 0,
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "white",
                          }}
                        >
                          {index === 0
                            ? formatNumberUniversal(token0DepositAmount)
                            : formatNumberUniversal(token1DepositAmount)}
                        </h5>
                      </Col>
                    </Col>
                  ))}
                </Col>
              </Row>
              <Row
                style={{
                  width: "100%",
                  margin: "24px 0px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h6
                  style={{
                    margin: 0,
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "white",
                  }}
                >
                  Set Swap Fee
                </h6>
                <div
                  style={{
                    backgroundColor: "#062148",
                    borderRadius: "8px",
                    padding: "10px 12px",
                    width: "117px",
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {Number(fee / 10000)}%
                </div>
              </Row>
              <Button
                type="primary"
                style={{ width: "100%" }}
                onClick={handleCreatePool}
                loading={loading}
              >
                {pool !== null ? "Add Position" : "Create pool"}
              </Button>
            </Row>
          )}
        </Row>
      </Modal>
    </>
  );
};

export default CreatePool;

interface SelectedToken {
  name: string;
  symbol: string;
  contractAddress: string;
  image: string;
  decimals: number;
}
